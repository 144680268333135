import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Modal, Button } from "react-bootstrap";
import Wallet from "../../../Components/User/Wallet";
import History from "../../../Components/User/History";
import UserModal from "../../../Components/User/Stat/Modal";
import storage from "../../../../Storage";
import { __, decode, defaultAvatar, encode, getUID, toUSD } from "../../../../Helper";
import PrivateMessage from "./../PrivateMessage";
import Logout from "./../../Auth/Logout";
import styled from "styled-components";
import { Heart, Menu, PencilIcon, User2, User2Icon, UserRound, Wallet2, X } from "lucide-react";
import UserCreditModal from './WalletModal'
import socket from "../../../../Socket";
import C from '../../../../Constant';
import { getVipLevelDetails } from "../../VipClub/calculateLevel";

const Container = styled.div`
  background-color: #1E2024;
  display: flex;
  border-radius: 26px;
  justify-content: flex-end;
  align-items: center;
`;

const CubBalance = styled.div`
  color: #99999;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
`;

const ModalTop = styled.div`
 display:flex;
 justify-content:space-between;
 width:100%;;
`;
const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #17181B;
    border-radius: 15px;
    border: none;
  }
`;

const ModalHeader = styled(Modal.Header)`
  border-bottom: none;
  padding: 20px;
`;

const ModalBody = styled(Modal.Body)`
  padding: 0 20px 20px;
`;

const ContentContainer = styled.div`
 display:flex;
 justify-content:space-between;
 width:100%;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const StyledButtonNew = styled.button`
  background-color: #56CF16;
  color: white;
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  width: 100%;
  max-width: 20rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #56CF16;
    transform: scale(1.05);
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #a0a0a0;
  font-family:normal;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 20px;
  background-color: #25282C;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  color: #ffffff;
  font-size: 12px;
`;




const StyledButton = styled(Button)`
  background-color: ${props => props.primary ? '#6C5CE7' : '#4A4D56'};
  border: none;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  padding: 12px;
  margin-bottom: 10px;
  transition: all 0.3s ease;

  &:hover, &:focus {
    background-color: ${props => props.primary ? '#5849C2' : '#3A3D46'};
  }
`;

const HertContainer = styled.div`
background-color:#1C1D21;
padding:0px 0.75rem;
border-radius:20px;

`;
const FlexContainer = styled.div`
display:flex;
align-items:center;
gap:5px;

`;
const FlexContainerStar = styled.div`
display:flex;
align-items:center;
gap:5px;
justify-content:center
`;

const Pencil = styled.div`
display:flex;
justify-content:center
background-color:#1C1D21;
padding: 0.75rem;
border-radius:50%;
`;


const UserContainer = styled.div`
display:flex;
justify-content:space-between;
gap:5px;
padding:5px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  background-color: #1e1e1e;
  padding: 20px;
  border-radius: 8px;
  max-width: 300px;
`;

const GridItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  font-size: 14px;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;


const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
  margin-right: 12px;
  align-items: center;
`;

const MenuItemContent = styled.div`
  color: #676F78;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 5px 12px;
  border-radius: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #222428;
  }
`;


const IconWrapperNew = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
`;

const StyledImg = styled.img`
  height: 18px;
  width: 18px;
  filter: invert(48%) sepia(6%) saturate(426%) hue-rotate(167deg) brightness(94%) contrast(87%);
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &:hover {
    opacity: 0.8;
  }
`;

const ProgressBarContainer = styled.div`
  background-color: #1e2024;
  border-radius: 20px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ProgressBarText = styled.span`
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
`;

const ProgressBarWrapper = styled.div`
  background-color: #424A55;
  border-radius: 20px;
  height: 8px;
  width: 85%;

`;

const ProgressBarFill = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  height: 100%;
  width: ${props => props.progress}%;
`;


class User extends React.Component {
    constructor(props) {
        super(props);
        const currentName = storage.getKey('name') !== undefined ? storage.getKey('name') : 'Guest';
        this.state = {
            level: 1,
            displayUid: getUID,
            name: currentName,
            avatar: defaultAvatar,
            showPopup: false,
            showPopupUserProfile: false,
            showPopupUserProfileStatistics: false,
            showWalletModal: false,
            showCurrencyModal: false,
            activeSection: 'deposit',
            haveData: '',
            data: [],
            currentLevel: 'vip 0',
            nextLevel: 'vip 1',
            progress: 0,
            xpNeeded: 1,
            currentXP: 0,
            vipDetails: null,
            wageredAmount: null,
            showEditUi: false,
            isUpdatingUsername: false,
            newUsername: currentName, // Initialize with current username
            updateError: null
        };
        this.progressAnimationRef = React.createRef();
    }


    handleUsernameChange = (event) => {
        this.setState({ newUsername: event.target.value });
    }

    updateUsername = async () => {
        const { newUsername, displayUid } = this.state;
        
        if (!newUsername.trim()) {
            this.setState({ updateError: 'Username cannot be empty' });
            return;
        }

        this.setState({ isUpdatingUsername: true, updateError: null });

        try {
            const response = await fetch('https://api.bitrubix.games/editProfile', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: displayUid,
                    username: newUsername.trim()
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update username');
            }

            const data = await response.json();

            console.log("datat is", data)


            
            // Update local storage and state with new username
            storage.setKey('name', newUsername);
            this.setState({ 
                name: newUsername, 
                showEditUi: false, 
                // newUsername: '' 
            });

            // Optionally, refresh user info
            socket.emit(C.USER_INFO, encode({
                id: getUID,
                coin: storage.getKey('coin') ? storage.getKey('coin') : '',
                rate: null,
                game: 'all',
                first: true
            }));

        } catch (error) {
            this.setState({ updateError: 'Failed to update username. Please try again.' });
        } finally {
            this.setState({ isUpdatingUsername: false });
        }
    }

    getUserInfo = (data) => {
        if (data.status) {
            console.log("data received", data);
            this.setState({ haveData: 'ok', data: data });

            let wageredAmount = parseFloat(data?.profit).toFixed(4);
            if (wageredAmount) {
                this.setState({ wageredAmount });
                const vipDetails = getVipLevelDetails(wageredAmount);
                this.setState({ vipDetails });
                this.animateProgress(parseFloat(vipDetails.completionPercentage));
            }
        } else {
            this.setState({ notFound: true });
        }
    };

    componentDidMount() {
        socket.on(C.USER_INFO, data => this.getUserInfo(decode(data)));
        socket.emit(C.USER_INFO, encode({
            id: getUID,
            coin: storage.getKey('coin') ? storage.getKey('coin') : '',
            rate: null,
            game: 'all',
            first: true
        }));
    }

    componentWillUnmount() {
        if (this.progressAnimationRef.current) {
            cancelAnimationFrame(this.progressAnimationRef.current);
        }
    }

    animateProgress = (targetProgress) => {
        let currentProgress = 0;
        const duration = 1000; // 1 second animation
        const startTime = performance.now();

        const animate = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);

            currentProgress = progress * targetProgress;
            this.setState({ progress: currentProgress });

            if (progress < 1) {
                this.progressAnimationRef.current = requestAnimationFrame(animate);
            }
        };

        this.progressAnimationRef.current = requestAnimationFrame(animate);
    };

    handleTogglePopup = () => {
        console.log("get ui", getUID)
        socket.emit(C.USER_INFO, encode({ id: getUID, coin: storage.getKey('coin') ? storage.getKey('coin') : '', rate: null, game: 'all', first: true }));
        this.setState(prevState => ({ showPopup: !prevState.showPopup }));
        this.setState((prevState)=>({showEditUi:false}))
    }

    handleTogglePopupProfile = () => {
        console.log("profile")
        this.setState(prevState => ({ showPopupUserProfile: !prevState.showPopupUserProfile }));
    }

    handleTogglePopupProfileStatistics = () => {
        this.setState(prevState => ({ showPopupUserProfileStatistics: !prevState.showPopupUserProfileStatistics }));
    }

    render() {
        const { 
            displayUid, level, name, avatar, showPopup, showPopupUserProfile, 
            showPopupUserProfileStatistics, vipDetails, progress, showEditUi,
            isUpdatingUsername, updateError, newUsername
        } = this.state;
        const {t} = this.props

        console.log("show edit", showEditUi)
        
        let uid = displayUid;
        if (__.isNaN(uid)) {
            uid = name;
        }



        return (
            <li className={"user-dropdown"}>
                <Dropdown right="true">
                    <Container>
                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="dropdown-split-user" onClick={this.handleTogglePopup}>
                                <img
                                    src="https://img2.nanogames.io/avatar/589929/s?t=1727193471914"
                                    alt="profile-user"
                                    style={{ height: '38px', width: '38px', borderRadius: '50%', objectFit: 'contain' }}
                                />
                            </Dropdown.Toggle>
                        </Dropdown>

                        <StyledModal show={showPopup} onHide={this.handleTogglePopup} centered>
                            <ModalHeader>
                                <div
                                    style={{ fontSize: '16px', fontWeight: 'bold' }}>User Profile</div>

                                <X onClick={this.handleTogglePopup} size={24} />

                            </ModalHeader>
                            <ModalBody>
                            

                                {
                                    this.state.showEditUi ? (
                                        <div style={{width:"100%", height:'50vh'}}>
                                               <div>
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img style={{width:'100px', height:'100px', borderRadius:'50%'}} src="https://img2.nanogames.io/avatar/589929/s?t=1727260678731" />
            {/* <div style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>{name}</div> */}
            {/* <FlexContainerStar>
                <img style={{ width: '20px' }} class="img-star" alt="level-star" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAMAAAC0TEcTAAAAe1BMVEUAAAD29vzd3+iSmKl4fozo6PHLztqRl6jGydS3vcvW2eHt7/bR1N6lpaWXnK2Znaqeo7WZpbCGi5t8g5OSl6mTmaikqbiLkaKSlqmVmKq8wcyytsWrsL6Sl6l4gIyVmKuWmap3fo6Umql5gYx8gY2Ik556foySmauJjJu6tS+cAAAAKXRSTlMAWVlZWVlZWFlZWVlZBFkQWQlZWU43WVlUJ1lZWUs+Lh5RQjAjF0dGKVUfY0EAAAIeSURBVEjHzdfdcqsgFAVgASEKqPlva6xpmqTt+z/hIYDdkwE3OOem66LTOH4TFjGzSTGX7nDoimVpzivGVudmiXl7ZTavb9nk/s5+837PIpuDvXut9dr+c9hklTFpBTERLWMZ1T727JFaERtV25f7D4R03/aegZPf8MFe+u4SZSR5ikSqNW6b24oEqVq3/Q1aBoJV6z7t1SMlM6FHe8NnF34yBAl8akgZtFrnnpkTJ8nwk3uyumIfK4NX2xcr81eQWKIbKR7P1QPVUcN5TKmTR1XUmESu0hpB3CYgFEOKh0pRiiJvnmpxiiAwVgHBEBBwZmUYAgGhkqKIRyKFEBJBKkpM9JJ3osIF7/RMtDcyfyOkmEJxRIKVmcgUUgERmqYQmcpAaBopKLMAUfEcnUZKiqVIUboYcRpBMo6ALETwnXFrkiY6hTidorWE+6TWNI6ApAKoVjQ/J49Yn296ZpAbAOsqj1RrNwD8qBl0mujBjxoYarXEiaxhqMH4bCt0Za0fn8GgFnNEwKAOjwSDjBE5zJx2mjNz1ULjyrDouWr3wtz2x7aZveyKWMrSs6MGoo+elGUcmWxX7oAgfRl3CFhtyxJBhrlq/cP0rowlKCqnan0/lSmTCBiQNIJqrkwaAYMyaQRr3G53ZT6C/F30dWua29cSdLl2/nt2vWSi8dbAxeY2osi/SXDS31wvKBrvRTT3cQ6NPxvkV9XPWPxf/gH/cTH6/dEd9gAAAABJRU5ErkJggg=="></img>
                <img style={{ width: '20px' }} class="img-star" alt="level-star" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAMAAAC0TEcTAAAAe1BMVEUAAAD29vzd3+iSmKl4fozo6PHLztqRl6jGydS3vcvW2eHt7/bR1N6lpaWXnK2Znaqeo7WZpbCGi5t8g5OSl6mTmaikqbiLkaKSlqmVmKq8wcyytsWrsL6Sl6l4gIyVmKuWmap3fo6Umql5gYx8gY2Ik556foySmauJjJu6tS+cAAAAKXRSTlMAWVlZWVlZWFlZWVlZBFkQWQlZWU43WVlUJ1lZWUs+Lh5RQjAjF0dGKVUfY0EAAAIeSURBVEjHzdfdcqsgFAVgASEKqPlva6xpmqTt+z/hIYDdkwE3OOem66LTOH4TFjGzSTGX7nDoimVpzivGVudmiXl7ZTavb9nk/s5+837PIpuDvXut9dr+c9hklTFpBTERLWMZ1T727JFaERtV25f7D4R03/aegZPf8MFe+u4SZSR5ikSqNW6b24oEqVq3/Q1aBoJV6z7t1SMlM6FHe8NnF34yBAl8akgZtFrnnpkTJ8nwk3uyumIfK4NX2xcr81eQWKIbKR7P1QPVUcN5TKmTR1XUmESu0hpB3CYgFEOKh0pRiiJvnmpxiiAwVgHBEBBwZmUYAgGhkqKIRyKFEBJBKkpM9JJ3osIF7/RMtDcyfyOkmEJxRIKVmcgUUgERmqYQmcpAaBopKLMAUfEcnUZKiqVIUboYcRpBMo6ALETwnXFrkiY6hTidorWE+6TWNI6ApAKoVjQ/J49Yn296ZpAbAOsqj1RrNwD8qBl0mujBjxoYarXEiaxhqMH4bCt0Za0fn8GgFnNEwKAOjwSDjBE5zJx2mjNz1ULjyrDouWr3wtz2x7aZveyKWMrSs6MGoo+elGUcmWxX7oAgfRl3CFhtyxJBhrlq/cP0rowlKCqnan0/lSmTCBiQNIJqrkwaAYMyaQRr3G53ZT6C/F30dWua29cSdLl2/nt2vWSi8dbAxeY2osi/SXDS31wvKBrvRTT3cQ6NPxvkV9XPWPxf/gH/cTH6/dEd9gAAAABJRU5ErkJggg=="></img>
                <img style={{ width: '20px' }} class="img-star" alt="level-star" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAMAAAC0TEcTAAAAe1BMVEUAAAD29vzd3+iSmKl4fozo6PHLztqRl6jGydS3vcvW2eHt7/bR1N6lpaWXnK2Znaqeo7WZpbCGi5t8g5OSl6mTmaikqbiLkaKSlqmVmKq8wcyytsWrsL6Sl6l4gIyVmKuWmap3fo6Umql5gYx8gY2Ik556foySmauJjJu6tS+cAAAAKXRSTlMAWVlZWVlZWFlZWVlZBFkQWQlZWU43WVlUJ1lZWUs+Lh5RQjAjF0dGKVUfY0EAAAIeSURBVEjHzdfdcqsgFAVgASEKqPlva6xpmqTt+z/hIYDdkwE3OOem66LTOH4TFjGzSTGX7nDoimVpzivGVudmiXl7ZTavb9nk/s5+837PIpuDvXut9dr+c9hklTFpBTERLWMZ1T727JFaERtV25f7D4R03/aegZPf8MFe+u4SZSR5ikSqNW6b24oEqVq3/Q1aBoJV6z7t1SMlM6FHe8NnF34yBAl8akgZtFrnnpkTJ8nwk3uyumIfK4NX2xcr81eQWKIbKR7P1QPVUcN5TKmTR1XUmESu0hpB3CYgFEOKh0pRiiJvnmpxiiAwVgHBEBBwZmUYAgGhkqKIRyKFEBJBKkpM9JJ3osIF7/RMtDcyfyOkmEJxRIKVmcgUUgERmqYQmcpAaBopKLMAUfEcnUZKiqVIUboYcRpBMo6ALETwnXFrkiY6hTidorWE+6TWNI6ApAKoVjQ/J49Yn296ZpAbAOsqj1RrNwD8qBl0mujBjxoYarXEiaxhqMH4bCt0Za0fn8GgFnNEwKAOjwSDjBE5zJx2mjNz1ULjyrDouWr3wtz2x7aZveyKWMrSs6MGoo+elGUcmWxX7oAgfRl3CFhtyxJBhrlq/cP0rowlKCqnan0/lSmTCBiQNIJqrkwaAYMyaQRr3G53ZT6C/F30dWua29cSdLl2/nt2vWSi8dbAxeY2osi/SXDS31wvKBrvRTT3cQ6NPxvkV9XPWPxf/gH/cTH6/dEd9gAAAABJRU5ErkJggg=="></img>

                <img style={{ width: '20px' }} class="img-star" alt="level-star" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAMAAAC0TEcTAAAAe1BMVEUAAAD29vzd3+iSmKl4fozo6PHLztqRl6jGydS3vcvW2eHt7/bR1N6lpaWXnK2Znaqeo7WZpbCGi5t8g5OSl6mTmaikqbiLkaKSlqmVmKq8wcyytsWrsL6Sl6l4gIyVmKuWmap3fo6Umql5gYx8gY2Ik556foySmauJjJu6tS+cAAAAKXRSTlMAWVlZWVlZWFlZWVlZBFkQWQlZWU43WVlUJ1lZWUs+Lh5RQjAjF0dGKVUfY0EAAAIeSURBVEjHzdfdcqsgFAVgASEKqPlva6xpmqTt+z/hIYDdkwE3OOem66LTOH4TFjGzSTGX7nDoimVpzivGVudmiXl7ZTavb9nk/s5+837PIpuDvXut9dr+c9hklTFpBTERLWMZ1T727JFaERtV25f7D4R03/aegZPf8MFe+u4SZSR5ikSqNW6b24oEqVq3/Q1aBoJV6z7t1SMlM6FHe8NnF34yBAl8akgZtFrnnpkTJ8nwk3uyumIfK4NX2xcr81eQWKIbKR7P1QPVUcN5TKmTR1XUmESu0hpB3CYgFEOKh0pRiiJvnmpxiiAwVgHBEBBwZmUYAgGhkqKIRyKFEBJBKkpM9JJ3osIF7/RMtDcyfyOkmEJxRIKVmcgUUgERmqYQmcpAaBopKLMAUfEcnUZKiqVIUboYcRpBMo6ALETwnXFrkiY6hTidorWE+6TWNI6ApAKoVjQ/J49Yn296ZpAbAOsqj1RrNwD8qBl0mujBjxoYarXEiaxhqMH4bCt0Za0fn8GgFnNEwKAOjwSDjBE5zJx2mjNz1ULjyrDouWr3wtz2x7aZveyKWMrSs6MGoo+elGUcmWxX7oAgfRl3CFhtyxJBhrlq/cP0rowlKCqnan0/lSmTCBiQNIJqrkwaAYMyaQRr3G53ZT6C/F30dWua29cSdLl2/nt2vWSi8dbAxeY2osi/SXDS31wvKBrvRTT3cQ6NPxvkV9XPWPxf/gH/cTH6/dEd9gAAAABJRU5ErkJggg=="></img>
            </FlexContainerStar> */}

        </div>


    </div>
                                        <InputContainer>
                                            <Label>Username</Label>
                                            <Input
                                                type="text"
                                                value={newUsername} // This will now always be prefilled
                                                onChange={this.handleUsernameChange}
                                                placeholder="Enter new username"
                                                disabled={isUpdatingUsername}
                                            />
                                        </InputContainer>
                                        <Label>Do not use special punctuation, otherwise your account may not be supported.</Label>
                                        {updateError && <Label style={{color: 'red'}}>{updateError}</Label>}
                    
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                            <StyledButtonNew
                                                type="button"
                                                onClick={this.updateUsername}
                                                disabled={isUpdatingUsername}
                                            >
                                                {isUpdatingUsername ? 'Updating...' : 'Modify'}
                                            </StyledButtonNew>
                                        </div>
                                    </div>):(    <div>

<ContentContainer>
    <div>
        <HertContainer>
            <FlexContainer>
                <Heart size={10} />
                <div>9</div>
            </FlexContainer>
        </HertContainer>
    </div>
    <div>
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img class="avatar-panda " src="https://img2.nanogames.io/avatar/589929/s?t=1727260678731" />
            <div style={{ fontSize: '18px', fontWeight: 'bold', marginTop: '10px', textAlign: 'center' }}>{name}</div>
            <FlexContainerStar>
                <img style={{ width: '20px' }} class="img-star" alt="level-star" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAMAAAC0TEcTAAAAe1BMVEUAAAD29vzd3+iSmKl4fozo6PHLztqRl6jGydS3vcvW2eHt7/bR1N6lpaWXnK2Znaqeo7WZpbCGi5t8g5OSl6mTmaikqbiLkaKSlqmVmKq8wcyytsWrsL6Sl6l4gIyVmKuWmap3fo6Umql5gYx8gY2Ik556foySmauJjJu6tS+cAAAAKXRSTlMAWVlZWVlZWFlZWVlZBFkQWQlZWU43WVlUJ1lZWUs+Lh5RQjAjF0dGKVUfY0EAAAIeSURBVEjHzdfdcqsgFAVgASEKqPlva6xpmqTt+z/hIYDdkwE3OOem66LTOH4TFjGzSTGX7nDoimVpzivGVudmiXl7ZTavb9nk/s5+837PIpuDvXut9dr+c9hklTFpBTERLWMZ1T727JFaERtV25f7D4R03/aegZPf8MFe+u4SZSR5ikSqNW6b24oEqVq3/Q1aBoJV6z7t1SMlM6FHe8NnF34yBAl8akgZtFrnnpkTJ8nwk3uyumIfK4NX2xcr81eQWKIbKR7P1QPVUcN5TKmTR1XUmESu0hpB3CYgFEOKh0pRiiJvnmpxiiAwVgHBEBBwZmUYAgGhkqKIRyKFEBJBKkpM9JJ3osIF7/RMtDcyfyOkmEJxRIKVmcgUUgERmqYQmcpAaBopKLMAUfEcnUZKiqVIUboYcRpBMo6ALETwnXFrkiY6hTidorWE+6TWNI6ApAKoVjQ/J49Yn296ZpAbAOsqj1RrNwD8qBl0mujBjxoYarXEiaxhqMH4bCt0Za0fn8GgFnNEwKAOjwSDjBE5zJx2mjNz1ULjyrDouWr3wtz2x7aZveyKWMrSs6MGoo+elGUcmWxX7oAgfRl3CFhtyxJBhrlq/cP0rowlKCqnan0/lSmTCBiQNIJqrkwaAYMyaQRr3G53ZT6C/F30dWua29cSdLl2/nt2vWSi8dbAxeY2osi/SXDS31wvKBrvRTT3cQ6NPxvkV9XPWPxf/gH/cTH6/dEd9gAAAABJRU5ErkJggg=="></img>
                <img style={{ width: '20px' }} class="img-star" alt="level-star" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAMAAAC0TEcTAAAAe1BMVEUAAAD29vzd3+iSmKl4fozo6PHLztqRl6jGydS3vcvW2eHt7/bR1N6lpaWXnK2Znaqeo7WZpbCGi5t8g5OSl6mTmaikqbiLkaKSlqmVmKq8wcyytsWrsL6Sl6l4gIyVmKuWmap3fo6Umql5gYx8gY2Ik556foySmauJjJu6tS+cAAAAKXRSTlMAWVlZWVlZWFlZWVlZBFkQWQlZWU43WVlUJ1lZWUs+Lh5RQjAjF0dGKVUfY0EAAAIeSURBVEjHzdfdcqsgFAVgASEKqPlva6xpmqTt+z/hIYDdkwE3OOem66LTOH4TFjGzSTGX7nDoimVpzivGVudmiXl7ZTavb9nk/s5+837PIpuDvXut9dr+c9hklTFpBTERLWMZ1T727JFaERtV25f7D4R03/aegZPf8MFe+u4SZSR5ikSqNW6b24oEqVq3/Q1aBoJV6z7t1SMlM6FHe8NnF34yBAl8akgZtFrnnpkTJ8nwk3uyumIfK4NX2xcr81eQWKIbKR7P1QPVUcN5TKmTR1XUmESu0hpB3CYgFEOKh0pRiiJvnmpxiiAwVgHBEBBwZmUYAgGhkqKIRyKFEBJBKkpM9JJ3osIF7/RMtDcyfyOkmEJxRIKVmcgUUgERmqYQmcpAaBopKLMAUfEcnUZKiqVIUboYcRpBMo6ALETwnXFrkiY6hTidorWE+6TWNI6ApAKoVjQ/J49Yn296ZpAbAOsqj1RrNwD8qBl0mujBjxoYarXEiaxhqMH4bCt0Za0fn8GgFnNEwKAOjwSDjBE5zJx2mjNz1ULjyrDouWr3wtz2x7aZveyKWMrSs6MGoo+elGUcmWxX7oAgfRl3CFhtyxJBhrlq/cP0rowlKCqnan0/lSmTCBiQNIJqrkwaAYMyaQRr3G53ZT6C/F30dWua29cSdLl2/nt2vWSi8dbAxeY2osi/SXDS31wvKBrvRTT3cQ6NPxvkV9XPWPxf/gH/cTH6/dEd9gAAAABJRU5ErkJggg=="></img>
                <img style={{ width: '20px' }} class="img-star" alt="level-star" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAMAAAC0TEcTAAAAe1BMVEUAAAD29vzd3+iSmKl4fozo6PHLztqRl6jGydS3vcvW2eHt7/bR1N6lpaWXnK2Znaqeo7WZpbCGi5t8g5OSl6mTmaikqbiLkaKSlqmVmKq8wcyytsWrsL6Sl6l4gIyVmKuWmap3fo6Umql5gYx8gY2Ik556foySmauJjJu6tS+cAAAAKXRSTlMAWVlZWVlZWFlZWVlZBFkQWQlZWU43WVlUJ1lZWUs+Lh5RQjAjF0dGKVUfY0EAAAIeSURBVEjHzdfdcqsgFAVgASEKqPlva6xpmqTt+z/hIYDdkwE3OOem66LTOH4TFjGzSTGX7nDoimVpzivGVudmiXl7ZTavb9nk/s5+837PIpuDvXut9dr+c9hklTFpBTERLWMZ1T727JFaERtV25f7D4R03/aegZPf8MFe+u4SZSR5ikSqNW6b24oEqVq3/Q1aBoJV6z7t1SMlM6FHe8NnF34yBAl8akgZtFrnnpkTJ8nwk3uyumIfK4NX2xcr81eQWKIbKR7P1QPVUcN5TKmTR1XUmESu0hpB3CYgFEOKh0pRiiJvnmpxiiAwVgHBEBBwZmUYAgGhkqKIRyKFEBJBKkpM9JJ3osIF7/RMtDcyfyOkmEJxRIKVmcgUUgERmqYQmcpAaBopKLMAUfEcnUZKiqVIUboYcRpBMo6ALETwnXFrkiY6hTidorWE+6TWNI6ApAKoVjQ/J49Yn296ZpAbAOsqj1RrNwD8qBl0mujBjxoYarXEiaxhqMH4bCt0Za0fn8GgFnNEwKAOjwSDjBE5zJx2mjNz1ULjyrDouWr3wtz2x7aZveyKWMrSs6MGoo+elGUcmWxX7oAgfRl3CFhtyxJBhrlq/cP0rowlKCqnan0/lSmTCBiQNIJqrkwaAYMyaQRr3G53ZT6C/F30dWua29cSdLl2/nt2vWSi8dbAxeY2osi/SXDS31wvKBrvRTT3cQ6NPxvkV9XPWPxf/gH/cTH6/dEd9gAAAABJRU5ErkJggg=="></img>

                <img style={{ width: '20px' }} class="img-star" alt="level-star" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAABGCAMAAAC0TEcTAAAAe1BMVEUAAAD29vzd3+iSmKl4fozo6PHLztqRl6jGydS3vcvW2eHt7/bR1N6lpaWXnK2Znaqeo7WZpbCGi5t8g5OSl6mTmaikqbiLkaKSlqmVmKq8wcyytsWrsL6Sl6l4gIyVmKuWmap3fo6Umql5gYx8gY2Ik556foySmauJjJu6tS+cAAAAKXRSTlMAWVlZWVlZWFlZWVlZBFkQWQlZWU43WVlUJ1lZWUs+Lh5RQjAjF0dGKVUfY0EAAAIeSURBVEjHzdfdcqsgFAVgASEKqPlva6xpmqTt+z/hIYDdkwE3OOem66LTOH4TFjGzSTGX7nDoimVpzivGVudmiXl7ZTavb9nk/s5+837PIpuDvXut9dr+c9hklTFpBTERLWMZ1T727JFaERtV25f7D4R03/aegZPf8MFe+u4SZSR5ikSqNW6b24oEqVq3/Q1aBoJV6z7t1SMlM6FHe8NnF34yBAl8akgZtFrnnpkTJ8nwk3uyumIfK4NX2xcr81eQWKIbKR7P1QPVUcN5TKmTR1XUmESu0hpB3CYgFEOKh0pRiiJvnmpxiiAwVgHBEBBwZmUYAgGhkqKIRyKFEBJBKkpM9JJ3osIF7/RMtDcyfyOkmEJxRIKVmcgUUgERmqYQmcpAaBopKLMAUfEcnUZKiqVIUboYcRpBMo6ALETwnXFrkiY6hTidorWE+6TWNI6ApAKoVjQ/J49Yn296ZpAbAOsqj1RrNwD8qBl0mujBjxoYarXEiaxhqMH4bCt0Za0fn8GgFnNEwKAOjwSDjBE5zJx2mjNz1ULjyrDouWr3wtz2x7aZveyKWMrSs6MGoo+elGUcmWxX7oAgfRl3CFhtyxJBhrlq/cP0rowlKCqnan0/lSmTCBiQNIJqrkwaAYMyaQRr3G53ZT6C/F30dWua29cSdLl2/nt2vWSi8dbAxeY2osi/SXDS31wvKBrvRTT3cQ6NPxvkV9XPWPxf/gH/cTH6/dEd9gAAAABJRU5ErkJggg=="></img>
            </FlexContainerStar>

        </div>


    </div>
    <div>
        <Pencil onClick={()=>this.setState({showEditUi:true})}>
            <PencilIcon size={10} />
        </Pencil>
    </div>

</ContentContainer>
<div style={{ backgroundColor: '#1E2024', padding: '18px 10px', width: '100%', borderRadius: '15px', marginTop: '10px' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div>Medals 0</div>
        {/* <div style={{ color: '#75AC26' }}>Details</div> */}
    </div>
    <div style={{ display: 'flex', gap: '10px', width: '100%', justifyContent: 'center', marginTop: '10px' }}>
        <img style={{ width: '44px', height: '63px', objectFit: 'contain' }} src="https://static.nanogames.io/assets/achieve_1.c87ad7ad.png" />
        <img style={{ width: '44px', height: '63px', objectFit: 'contain' }} src="https://static.nanogames.io/assets/achieve_1.c87ad7ad.png" />
        <img style={{ width: '44px', height: '63px', objectFit: 'contain' }} src="https://static.nanogames.io/assets/achieve_3.bf42dfa3.png" />
        <img style={{ width: '44px', height: '63px', objectFit: 'contain' }} src="https://static.nanogames.io/assets/achieve_4.c845bc7e.png" />
        <img style={{ width: '44px', height: '63px', objectFit: 'contain' }} src="https://static.nanogames.io/assets/achieve_5.7e08d516.png" />
        <img style={{ width: '44px', height: '63px', objectFit: 'contain' }} src="https://static.nanogames.io/assets/achieve_1.c87ad7ad.png" />
        <img style={{ width: '44px', height: '63px', objectFit: 'contain' }} src="https://static.nanogames.io/assets/achieve_1.c87ad7ad.png" />
    </div>
</div>
<div style={{ backgroundColor: '#1E2024', padding: '18px 10px', width: '100%', borderRadius: '15px', marginTop: '12px' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div>Statistics</div>
        {/* <div style={{ color: '#75AC26' }}>Details</div> */}
    </div>

    <div style={{ display: 'flex', gap: '10px', width: '100%', justifyContent: 'center', marginTop: '10px' }}>
        <div style={{ width: '100%', borderRadius: '15px', backgroundColor: '#1B1C1F', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>
                <div style={{ color: '#676D76', fontSize: '12px' }}>Total Wins</div>
                <div style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>{this.state.data?.wined}</div>
            </div>
        </div>
        <div style={{ width: '100%', borderRadius: '15px', backgroundColor: '#1B1C1F', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>
                <div style={{ color: '#676D76', fontSize: '12px' }}>Total Bets</div>
                <div style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>{this.state.data?.played}</div>
            </div>
        </div>
        <div style={{ width: '100%', borderRadius: '15px', backgroundColor: '#1B1C1F', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>
                <div style={{ color: '#676D76', fontSize: '12px' }}>Total Wagered</div>
                <div style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>{this.state.data?.profit}</div>
            </div>
        </div>





    </div>
</div>
</div>)
                                }
                  
                            </ModalBody>
                        </StyledModal>


                        <StyledModal show={showPopupUserProfileStatistics} onHide={this.handleTogglePopupProfileStatistics} centered>
                            <ModalHeader>
                                <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Details</div>

                                <X onClick={this.handleTogglePopupProfileStatistics} size={24} />

                            </ModalHeader>
                            <ModalBody>
                                <div>

                                    <div style={{ backgroundColor: '#1E2024', padding: '18px 10px', width: '100%', borderRadius: '15px', marginTop: '12px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <div>Statistics</div>
                                            {/* <div style={{ color: '#75AC26' }}>Details</div> */}
                                        </div>

                                        <div style={{ display: 'flex', gap: '10px', width: '100%', justifyContent: 'center', marginTop: '10px' }}>
                                            <div style={{ width: '100%', borderRadius: '15px', backgroundColor: '#1B1C1F', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div>
                                                    <div style={{ color: '#676D76', fontSize: '12px' }}>Total Wins</div>
                                                    <div style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>{this.state.data?.wined}</div>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', borderRadius: '15px', backgroundColor: '#1B1C1F', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div>
                                                    <div style={{ color: '#676D76', fontSize: '12px' }}>Total Bets</div>
                                                    <div style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>{this.state.data?.played}</div>
                                                </div>
                                            </div>
                                            <div style={{ width: '100%', borderRadius: '15px', backgroundColor: '#1B1C1F', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div>
                                                    <div style={{ color: '#676D76', fontSize: '12px' }}>Total Wagered</div>
                                                    <div style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>{this.state.data?.profit}</div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                                {/* <StyledButton primary className="w-100">Edit Profile</StyledButton>
              <StyledButton className="w-100">View Settings</StyledButton> */}
                            </ModalBody>
                        </StyledModal>

                        <CubBalance>
                            <Dropdown>
                                <Dropdown.Toggle variant="link" id="dropdown-split-menu">
                                    <Menu style={{ marginTop: '2px' }} size={22} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ padding: '14px' }} className={"droper user-dropdown-detail"}>
                                    <UserInfo id={uid} level={level} name={name} avatar={avatar} t={t} />
                                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div>{vipDetails?.previousVipLevel || 'VIP 0'}</div>
                                        <div>{vipDetails?.vipLevel || 'VIP 1'}</div>
                                    </div>
                                    <ProgressBarContainer style={{ marginTop: '2px' }}>
                                        <ProgressBarWrapper>
                                            <ProgressBarFill progress={progress} />
                                        </ProgressBarWrapper>
                                        <ProgressBarText>{progress.toFixed(1)}%</ProgressBarText>
                                    </ProgressBarContainer>

                                    <div style={{ backgroundColor: '#1e2024', padding: '14px 20px', borderRadius: '12px', marginTop: '8px' }} className="user-links">
                                        {/* <Dropdown.Item className={"animated fadeIn"}>
                                            <Wallet t={t} />
                                        </Dropdown.Item>
                                        <Dropdown.Item className={"animated fadeIn py-0 px-1-"}>
                                            <History t={t} />
                                        </Dropdown.Item>
                                        <Dropdown.Item as={'button'} className={"animated fadeIn"}>
                                            <Link to={'/setting'} className={"dropdown-item text-white"}>
                                                <i className="dripicons-gear text-muted mr-2 text-drop" />
                                                Setting
                                            </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item as={'button'} className={"animated fadeIn"}>
                                            <Logout />
                                        </Dropdown.Item> */}

                                        <MenuContainer>
                                            <Column>
                                                <MenuItem>
                                                    <MenuItemContent onClick={this.handleTogglePopup}>
                                                        <IconWrapperNew>
                                                            <UserRound size={18} />
                                                        </IconWrapperNew>
                                                        <div>User Information</div>
                                                    </MenuItemContent>
                                                </MenuItem>
                                                <MenuItem>
                                                    <MenuItemContent onClick={() => this.setState({ showPopupUserProfileStatistics: true })}>
                                                        <IconWrapperNew>
                                                            <StyledImg src="/assets/images/nanoicons/stats.png" alt="Statistics" />
                                                        </IconWrapperNew>
                                                        <div>Statistics</div>
                                                    </MenuItemContent>
                                                </MenuItem>
                                                <MenuItem>
                                                    <MenuItemContent>
                                                        <IconWrapperNew>
                                                            <StyledImg src="/assets/images/nanoicons/transactions.png" alt="Transactions" />
                                                        </IconWrapperNew>
                                                        <div>Transactions</div>
                                                    </MenuItemContent>
                                                </MenuItem>
                                                <MenuItem>
                                                    <MenuItemContent>
                                                        <IconWrapperNew>
                                                            <StyledImg src="/assets/images/nanoicons/liveSupport.png" alt="Live Support" />
                                                        </IconWrapperNew>
                                                        <div>Live Support</div>
                                                    </MenuItemContent>
                                                </MenuItem>
                                                <MenuItem>
                                                    <StyledLink to="/affiliate">
                                                        <MenuItemContent>
                                                            <IconWrapperNew>
                                                                <StyledImg src="/assets/images/nanoicons/affiliates.png" alt="Affiliate" />
                                                            </IconWrapperNew>
                                                            <div style={{ fontSize: '14px', color: '#676F78' }}>Affiliate</div>
                                                        </MenuItemContent>
                                                    </StyledLink>
                                                </MenuItem>
                                            </Column>

                                            <Column>
                                                <MenuItemContent onClick={() => this.setState({ activeSection: 'deposit', showWalletModal: true })}>
                                                    <IconWrapperNew>
                                                        <Wallet2 size={18} />
                                                    </IconWrapperNew>
                                                    <div>Wallet</div>
                                                </MenuItemContent>
                                                <MenuItemContent onClick={() => {
                                                    this.setState({ activeSection: 'swap', showWalletModal: true });
                                                }}>
                                                    <IconWrapperNew>
                                                        <StyledImg src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />
                                                    </IconWrapperNew>
                                                    <div>Swap</div>
                                                </MenuItemContent>
                                                <MenuItemContent onClick={() => {
                                                    this.setState({ activeSection: 'vaultpro', showWalletModal: true });
                                                }}>
                                                    <IconWrapperNew>
                                                        <StyledImg src="/assets/images/nanoicons/vault.png" alt="Vault Pro" />
                                                    </IconWrapperNew>
                                                    <div>Vault Pro</div>
                                                </MenuItemContent>
                                                <StyledLink to="/rakeback">

                                                    <MenuItemContent>
                                                        <IconWrapperNew>
                                                            <StyledImg src="/assets/images/nanoicons/rewards.png" alt="Reward" />
                                                        </IconWrapperNew>
                                                        <div>Rakeback</div>

                                                    </MenuItemContent>
                                                </StyledLink>
                                                <StyledLink to="/vip-club">
                                                    <MenuItemContent>
                                                        <IconWrapperNew>
                                                            <StyledImg src="/assets/images/nanoicons/vipclub.png" alt="VIP club" />
                                                        </IconWrapperNew>
                                                        <div style={{ fontSize: '14px', color: '#676F78' }}>VIP club</div>
                                                    </MenuItemContent>
                                                </StyledLink>
                                            </Column>
                                        </MenuContainer>


                                    </div>

                                    <div style={{ marginTop: '12px' }}>

                                        <Dropdown.Item as={'button'} className={"animated fadeIn"}>
                                            <Logout />
                                        </Dropdown.Item>
                                    </div>





                                </Dropdown.Menu>
                            </Dropdown>
                        </CubBalance>
                    </Container>
                </Dropdown>

                <UserCreditModal

                    showWalletModal={this.state.showWalletModal}
                    showCurrencyModal={this.state.showCurrencyModal}
                    activeSection={this.state.activeSection}
                    onToggleWalletModal={() => this.setState(prevState => ({ showWalletModal: !prevState.showWalletModal }))}
                    onToggleCurrencyModal={() => this.setState(prevState => ({ showCurrencyModal: !prevState.showCurrencyModal }))}
                    onSetActiveSection={(sectionId) => this.setState({ activeSection: sectionId })}


                />


            </li>
        );
    }
}

class UserInfo extends React.Component {
    makeStars = (num) => {
        let stars = 5;
        let userLevel = stars - parseFloat(num);
        var g = [], o = [], f = [];
        for (var i = 0; i < userLevel; i++) {
            g.push(<span className="graysi">🏆</span>);
        }
        for (var i = 0; i < num; i++) {
            o.push(<span>🏆</span>);
        }
        f.push(o, g);
        return f;
    }
    render() {
        const { name, id, level, avatar, t } = this.props;





        return (
            <>
                <div >
                    <UserContainer>
                        <div style={{ display: 'flex', gap: '5px' }}>

                            {<UserModal t={t} username={name} id={id} menu={true} avatar={'/assets/images/avatar.png'} />}
                            <div>
                                <div className="text-white">{name}</div>

                                <div>{this.makeStars(level)}</div>
                            </div>
                        </div>
                        <Link style={{display:'flex', alignItems:'center', gap:'5px'}} to="/global-settings">
                        <img style={{height:'20px', width:'20px'}} src='/assets/images/normal-seetings.png' alt="Help Center Icon" />

                        <div >Global Settings</div>
                        </Link>
                    </UserContainer>

                    {/* <br /> */}
                </div>
            </>
        );
    }
}

export default User;
