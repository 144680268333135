import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  background-color: #1E2024;
  color: white;
  padding: 20px;
  border-radius:20px;
  font-family: Arial, sans-serif;
`;

const TabSwitcher = styled.div`
  display: flex;
  margin-bottom: 20px;
  backgroud-color:#2E2F38 !important;
`;

const Tab = styled.button`
  background-color: ${props => props.active ? '#2c3137' : 'transparent'};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight:bold;
  margin-right: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 10px;
  color: #8a8d92;
  font-weight: normal;
`;


const TableCell = styled.td`
  padding: 10px;
`;

const RaceInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #8a8d92;
`;

const GreenText = styled.span`
  color: #4caf50;
`;

const RankIcon = styled.div`
  width: 24px;
  height: 24px;
  background-color: ${props => props.rank <= 3 ? '#ffd700' : '#c0c0c0'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: #1e2328;
`;

const moveUpAnimation = keyframes`
//   0% { transform: translateY(10px); opacity: 0; }
//   100% { transform: translateY(0); opacity: 1; }
`;

const TableRow = styled.tr`
  background-color:transparent;
  transition: background-color 0.3s ease;
`;

const AnimatedTableRow = styled(TableRow)`
  animation: ${moveUpAnimation} 0.5s ease-out;
`;

const AnimatedTable = ({ data, visibleRowsCount = 10 }) => {
  const [visibleData, setVisibleData] = useState([]);
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStartIndex((prevIndex) => (prevIndex + 1) % (data.length - visibleRowsCount + 1));
    }, 1000);

    return () => clearInterval(interval);
  }, [data.length, visibleRowsCount]);

  useEffect(() => {
    setVisibleData(data.slice(startIndex, startIndex + visibleRowsCount));
  }, [startIndex, data, visibleRowsCount]);

  return (
    <>
       <Table>
      <thead>
        <tr>
          <TableHeader  style={{color:'#99A4B0' , fontWeight:'bold'}}>Game</TableHeader>
          <TableHeader  style={{color:'#99A4B0' , fontWeight:'bold'}}>Player</TableHeader>
          <TableHeader  style={{color:'#99A4B0' , fontWeight:'bold'}}>Bet ID</TableHeader>
          <TableHeader  style={{color:'#99A4B0' , fontWeight:'bold'}}>Payout</TableHeader>
          <TableHeader  style={{color:'#99A4B0' , fontWeight:'bold'}}>Profit</TableHeader>
        </tr>
      </thead>
      <tbody>
        {visibleData.map((row, index) => (
          <AnimatedTableRow key={`${startIndex}-${index}`} even={index % 2 === 0}>
            <TableCell style={{color:'#99A4B0' , fontWeight:'bold'}}>{row.game}</TableCell>
            <TableCell style={{ fontWeight:'bold'}}>{row.user}</TableCell>
            <TableCell style={{color:'#99A4B0' , fontWeight:'bold'}}>{row.time}</TableCell>
            <TableCell style={{color:'#99A4B0' , fontWeight:'bold'}}>{row.amount}</TableCell>
            <TableCell><GreenText>{row.multiplier}</GreenText></TableCell>
          </AnimatedTableRow>
        ))}
      </tbody>
    </Table>
  
    {/* <Table>
      <thead>
        <tr>
          <TableHeader>Game</TableHeader>
          <TableHeader>User</TableHeader>
          <TableHeader>Time</TableHeader>
          <TableHeader>Amount</TableHeader>
          <TableHeader>Multiplier</TableHeader>
          <TableHeader>Result</TableHeader>
        </tr>
      </thead>
      <tbody>
        {visibleData.map((row, index) => (
          <AnimatedTableRow key={`${startIndex}-${index}`} even={index % 2 === 0}>
            <TableCell>{row.game}</TableCell>
            <TableCell>{row.user}</TableCell>
            <TableCell>{row.time}</TableCell>
            <TableCell>{row.amount}</TableCell>
            <TableCell>{row.multiplier}</TableCell>
            <TableCell><GreenText>{row.result}</GreenText></TableCell>
          </AnimatedTableRow>
        ))}
      </tbody>
    </Table> */}
  </>
  );
};
function CasinoLeaderboard() {
  const [activeTab, setActiveTab] = useState('Social Casino');

  const socialCasinoData = [
    {
      game: 'Limbo',
      user: 'Hidden',
      time: '1632849275847',
      amount: '0.87x',
      multiplier: '0.038485000',
    },
    {
      game: 'Classic Dice',
      user: 'Hidden',
      time: '1598376492837',
      amount: '2.34x',
      multiplier: '0.045672000',
    },
    {
      game: 'Plinko',
      user: 'Hidden',
      time: '1612938475629',
      amount: '0.05x',
      multiplier: '0.029384000',
    },
    {
      game: 'Hash Dice',
      user: 'Hidden',
      time: '1645029384756',
      amount: '1.76x',
      multiplier: '0.052937000',
    },
    {
      game: 'Wheel',
      user: 'Hidden',
      time: '1678394857263',
      amount: '3.21x',
      multiplier: '0.061284000',
    },
    {
      game: 'Limbo',
      user: 'Hidden',
      time: '1623948576234',
      amount: '0.12x',
      multiplier: '0.033765000',
    },
    {
      game: 'Classic Dice',
      user: 'Hidden',
      time: '1609283746592',
      amount: '4.98x',
      multiplier: '0.078392000',
    },
    {
      game: 'Plinko',
      user: 'Hidden',
      time: '1656293847569',
      amount: '0.54x',
      multiplier: '0.041593000',
    },
    {
      game: 'Hash Dice',
      user: 'Hidden',
      time: '1687439275634',
      amount: '1.23x',
      multiplier: '0.049281000',
    },
    {
      game: 'Wheel',
      user: 'Hidden',
      time: '1634958273645',
      amount: '5.67x',
      multiplier: '0.082746000',
    },
    {
      game: 'Limbo',
      user: 'Hidden',
      time: '1601234567890',
      amount: '0.03x',
      multiplier: '0.031245000',
    },
    {
      game: 'Classic Dice',
      user: 'Hidden',
      time: '1678901234567',
      amount: '2.89x',
      multiplier: '0.057382000',
    },
    {
      game: 'Plinko',
      user: 'Hidden',
      time: '1645678901234',
      amount: '0.76x',
      multiplier: '0.043921000',
    },
    {
      game: 'Hash Dice',
      user: 'Hidden',
      time: '1612345678901',
      amount: '3.45x',
      multiplier: '0.064829000',
    },
    {
      game: 'Wheel',
      user: 'Hidden',
      time: '1689012345678',
      amount: '1.98x',
      multiplier: '0.053678000',
    },
    {
      game: 'Limbo',
      user: 'Hidden',
      time: '1656789012345',
      amount: '0.34x',
      multiplier: '0.037492000',
    },
    {
      game: 'Classic Dice',
      user: 'Hidden',
      time: '1623456789012',
      amount: '4.21x',
      multiplier: '0.072345000',
    },
    {
      game: 'Plinko',
      user: 'Hidden',
      time: '1690123456789',
      amount: '0.09x',
      multiplier: '0.032178000',
    },
    {
      game: 'Hash Dice',
      user: 'Hidden',
      time: '1657890123456',
      amount: '5.87x',
      multiplier: '0.086543000',
    },
    {
      game: 'Wheel',
      user: 'Hidden',
      time: '1624567890123',
      amount: '2.56x',
      multiplier: '0.058921000',
    },
  ];
  

  const raceLeaderboardData = [
    { rank: 1, user: 'Hidden', played: '15,530,015,3...', prize: '7,000,000.00' },
    { rank: 2, user: 'Hidden', played: '348,911,530,...', prize: '4,750,000.00' },
    // Add more data as needed
  ];

  return (

    <div style={{marginTop:'12px'}}>
    
    <div>
    <TabSwitcher>
        <Tab active={activeTab === 'Social Casino'} onClick={() => setActiveTab('Social Casino')}>Latest Wins</Tab>
        {/* <Tab active={activeTab === 'Contest'} >Contest</Tab> */}
      </TabSwitcher>
    </div>
    <Container>
     
      
      {activeTab === 'Social Casino' && (
        <AnimatedTable data={socialCasinoData} />
      )}
      
      {activeTab === 'Race Leaderboard' && (
        <>
       <div>Sample</div>
        </>
      )}
    </Container>
    </div>
  );
}

export default CasinoLeaderboard;