// import React, { Component } from 'react';
// import { Modal } from 'react-bootstrap';
// import styled from 'styled-components';
// import Cookies from 'js-cookie';
// import storage from '../../../Storage';
// import socket from '../../../Socket';
// import { Event, wait, decode, encode, sendNotfication } from '../../../Helper';
// import C from '../../../Constant';

// const StyledModal = styled(Modal)`
//   .modal-content {
//     background-color: #1e2124;
//     color: white;
//     border-radius: 18px;
//     overflow: hidden;
//   }
// `;

// const ModalHeader = styled.div`
//   background-color: #43B30B;
//   padding: 15px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-bottom: 2px solid #2f7d08;
// `;

// const CloseButton = styled.button`
//   background: none;
//   border: none;
//   color: white;
//   font-size: 24px;
//   cursor: pointer;
//   display: flex;
//   justify-content: flex-end;
// `;

// const ModalBody = styled(Modal.Body)`
//   padding: 20px;
// `;

// const StyledForm = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 15px;
// `;

// const StyledInput = styled.input`
//   background-color: #2b2f33;
//   border: 1px solid #3a3f44;
//   border-radius: 12px;
//   color: white;
//   padding: 14px;
//   &::placeholder {
//     color: #6c757d;
//   }
//   &:focus {
//     outline: none;
//     border-color: #43B30B;
//     box-shadow: 0 0 0 2px rgba(67, 179, 11, 0.2);
//   }
// `;

// const ButtonContainer = styled.div`
//   display: flex;
//   gap: 10px;
//   justify-content: center;
// `;

// const Button = styled.button`
//   padding: 20px 30px;
//   border-radius: 30px;
//   font-weight: bold;
//   cursor: pointer;
//   transition: all 0.3s ease;
// `;

// const RegisterButton = styled(Button)`
//   background-color: #8b5cf6;
//   color: white;
//   border: none;
//   width: 100%;
//   max-width: 200px;

//   @media (max-width: 768px) {
//     max-width: 150px;
//   }

//   @media (max-width: 480px) {
//     max-width: 100%;
//   }

//   &:hover {
//     background-color: #7c3aed;
//   }
// `;

// const Labeltext = styled.div`
//   color: #696F79;
//   font-size: 14px;
// `;

// const StyledSignUpButton = styled.div`
//   font-size: 16px;
//   color: #43B30B !important;
//   background-color: transparent;
//   border: none;
//   border-radius: 28px;
//   padding: 5px 10px;
//   font-weight: bold;
//   cursor: pointer;
//   white-space: nowrap;
// `;

// class Register extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             show: this.props.show ? this.props.show : false,
//             username: '',
//             email: '',
//             password: '',
//             confirmPassword: '',
//             referral: '',
//             status: false,
//             submitted: false,
//             disabled: false,
//             effect: 'zoomIn',
//             termsAccepted: false
//         };
//     }

//     componentDidMount() {
//         socket.on(C.REGISTER_USER, data => this.setRegister(decode(data)));
//         Event.on('register_form', () => {
//             this.handleShow();
//         });
//     }

//     setRegister = (data) => {
//         if (data.status === true) {
//             sendNotfication('Successfully Registered, Please Wait...', 'success', 'bottom-left');
//             Cookies.set("session", data.token, { expires: 14 });
//             storage.setKey('logged', true);
//             storage.setKey('token', data.token);
//             storage.setKey('name', data.name);
//             storage.setKey('avatar', data.avatar);
//             storage.setKey('email', data.email);
//             storage.setKey('credit', data.credit);
//             storage.setKey('room', data.room);
//             storage.setKey('friends', data.friends);

//             wait(500).then(() => {
//                 window.location.reload();
//             });
//         } else {
//             this.setState({ submitted: false, disabled: false });
//             sendNotfication(data.status, 'error', 'bottom-left');
//         }
//     };

//     handleShow = () => {
//         this.setState({ show: true, effect: 'zoomIn' });
//     }

//     handleClose = () => {
//         this.setState({ show: false, effect: 'zoomOut', disabled: false, status: false, submitted: false });
//     }

//     handleSubmit = async (e) => {
//         e.preventDefault();

//         this.setState({ submitted: true, disabled: true, status: 'Please wait' });

//         const { username, email, password, confirmPassword, referral, termsAccepted } = this.state;

//         if (!(username && email && password && confirmPassword && termsAccepted)) {
//             this.setState({ disabled: false, status: false });
//             sendNotfication('Please fill all required fields and accept the terms', 'error', 'bottom-left');
//             return;
//         }

//         if (password !== confirmPassword) {
//             sendNotfication('Passwords do not match', 'error', 'bottom-left');
//             this.setState({ disabled: false, status: false });
//             return;
//         }

//         wait(200).then(() => {
//             socket.emit(C.REGISTER_USER, encode({
//                 username: username,
//                 email: email,
//                 password: password,
//                 referral: referral,
//                 recaptcha: 'google'
//             }));
//         });
//     }

//     handleInputChange = (e) => {
//         this.setState({ [e.target.name]: e.target.value });
//     }

//     handleCheckboxChange = (e) => {
//         this.setState({ [e.target.name]: e.target.checked });
//     }

//     render() {
//         const { show, username, email, password, confirmPassword, referral, disabled, termsAccepted } = this.state;

//         return (
//             <>
//                 <StyledSignUpButton onClick={e => this.handleShow(e)}>
//                     Sign up
//                 </StyledSignUpButton>
//                 <StyledModal
//                     size="md"
//                     centered
//                     show={show}
//                     onHide={this.handleClose}
//                     aria-labelledby="register-modal"
//                     className={`animated ${this.state.effect}`}
//                 >
//                     <ModalHeader>
//                         <CloseButton onClick={this.handleClose}>×</CloseButton>
//                     </ModalHeader>
//                     <ModalBody>
//                         <div className="text-center mb-4">
//                             <h3>CREATE YOUR ACCOUNT</h3>
//                         </div>
//                         <StyledForm onSubmit={this.handleSubmit}>
//                             <Labeltext>Username</Labeltext>
//                             <StyledInput
//                                 name="username"
//                                 value={username}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Username"
//                             />
//                             <Labeltext>Email Address</Labeltext>
//                             <StyledInput
//                                 type="email"
//                                 name="email"
//                                 value={email}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Email Address"
//                             />
//                             <Labeltext>Password</Labeltext>
//                             <StyledInput
//                                 type="password"
//                                 name="password"
//                                 value={password}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Password"
//                             />
//                             <Labeltext>Confirm Password</Labeltext>
//                             <StyledInput
//                                 type="password"
//                                 name="confirmPassword"
//                                 value={confirmPassword}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Confirm Password"
//                             />
//                             <Labeltext>Referral Code (Optional)</Labeltext>
//                             <StyledInput
//                                 name="referral"
//                                 value={referral}
//                                 onChange={this.handleInputChange}
//                                 placeholder="Referral Code"
//                             />
//                             <div>
//                                 <input
//                                     type="checkbox"
//                                     name="termsAccepted"
//                                     checked={termsAccepted}
//                                     onChange={this.handleCheckboxChange}
//                                     id="termsCheckbox"
//                                 />
//                                 <label htmlFor="termsCheckbox" style={{ marginLeft: '10px', color: '#696F79' }}>
//                                     I agree to the Terms of Service and Privacy Policy
//                                 </label>
//                             </div>
//                             <ButtonContainer>
//                                 <RegisterButton type="submit" disabled={disabled}>
//                                     {disabled && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>}
//                                     Sign Up
//                                 </RegisterButton>
//                             </ButtonContainer>
//                         </StyledForm>
//                         <div className="text-center mt-3">
//                             <span>Already have an account? </span>
//                             <a href="#" onClick={() => { this.handleClose(); Event.emit('login_form'); }}>Sign in</a>
//                         </div>
//                     </ModalBody>
//                 </StyledModal>
//             </>
//         );
//     }
// }

// export default Register;





import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import socket from "../../../Socket";
import storage from "../../../Storage";
import { decode, encode, getElement, sendNotfication, isEmail, Event, DEVELOPMENT, wait, randomString } from "../../../Helper";
import C from "../../../Constant";
import axios from 'axios';

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #1e2124;
    color: white;
    border-radius: 18px;
    overflow: hidden;
  }
`;

const ModalHeader = styled.div`
  background-color: #43B30B;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #2f7d08;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const ModalBody = styled(Modal.Body)`
  padding: 20px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const StyledInput = styled.input`
  background-color: #2b2f33;
  border: 1px solid #3a3f44;
  border-radius: 12px;
  color: white;
  padding: 14px;
  &::placeholder {
    color: #6c757d;
  }
  &:focus {
    outline: none;
    border-color: #43B30B;
    box-shadow: 0 0 0 2px rgba(67, 179, 11, 0.2);
  }
`;

const Labeltext = styled.div`
  color: #696F79;
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const Button = styled.button`
  padding: 20px 30px;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const SignUpButton = styled(Button)`
  background-color: #8b5cf6;
  color: white;
  border: none;
  width: 100%;
  max-width: 200px;

  @media (max-width: 768px) {
    max-width: 150px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }

  &:hover {
    background-color: #7c3aed;
  }
`;

const SignInButton = styled(Button)`
  background-color: #30353C;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 120px;

  @media (max-width: 768px) {
    max-width: 150px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
  }

  &:hover {
    background-color: #374151;
  }
`;

const StyledSignUpButton = styled.div`

  background-color: #45B70C;
  color: white;
  border: none;
  border-radius: 500px;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space:nowrap;

 
`;

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLogged: storage.getKey('logged'),
            show: this.props.show ? this.props.show : false,
            username: '',
            email: '',
            password: '',
            referral: this.props.referral || '',
            ruleChecked: false,
            submitted: false,
            disabled: false,
            effect: 'zoomIn'
        };
    }

    componentDidMount() {
        socket.on(C.REGISTER_USER, data => this.setRegister(decode(data)));
        Event.on('register_form', () => {
            this.handleShow();
        });
    }

    setRegister = (data) => {
        if (data.error) {
            this.setState({ disabled: false, submitted: false });
            return sendNotfication(data.error, 'success', 'bottom-left');
        }

        if (data.status) {

            console.log("datataaaa", data)
            this.setState({ submitted: false });
            wait(1000).then(() => {
                socket.emit(C.LOGIN_USER, encode({
                    username: data.name,
                    password: data.password,
                    recaptcha: 'google'
                }));
            });
        }
    };

    handleShow = () => {
        this.setState({ show: true, effect: 'zoomIn' });
    }

    handleClose = () => {
        this.setState({ show: false, effect: 'zoomOut', disabled: false, submitted: false, ruleChecked: false });
    }

    verifyRefrelCode = async()=> {
        try {

            const response= await axios.get(`https://api.bitrubix.games/verify-referral-code/${this.state.referral}`)
            console.log("response comes like", response)
    
            return response.valid;
            
        } catch (error) {
            console.log("error", error)
        }
    }

   handleSubmit =  async (e) => {
    var verified
        e.preventDefault();
        this.setState({ submitted: true, disabled: true });

      

        
        const { username, email, password, ruleChecked, referral } = this.state;
    if(referral!==null||referral!==""){
        console.log("ghus ja")
       verified= await this.verifyRefrelCode();
        if(verified==false){
            console.log("insdied false")
            this.setState({ disabled: false });
            sendNotfication('Please enter valid referrel Code', 'success', 'bottom-left');
            return
        }
    }

        if (!(username && password && email && ruleChecked)) {
            this.setState({ disabled: false });
            return;
        }

        if (username.length < 5) {
            this.setState({ disabled: false });
            sendNotfication('Please enter username more than 5 words', 'success', 'bottom-left');
            return;
        }

        if (!isEmail(email)) {
            this.setState({ disabled: false });
            sendNotfication('Please enter valid email address', 'success', 'bottom-left');
            return;
        }

        socket.emit(C.REGISTER_USER, encode({
            username: username,
            password: password,
            email: email,
            method: true,
            refree: referral||'',
            aff: storage.getKey('aff') ? storage.getKey('aff') : null
        }));
        this.handleClose()
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    checkRule = () => {
        this.setState(prevState => ({ ruleChecked: !prevState.ruleChecked }));
    }

    login = () => {
        this.handleClose();
        Event.emit('login_form');
    }

    render() {
        const { show, username, email, password, ruleChecked, disabled, referral } = this.state;

        return (
            <>
                <StyledSignUpButton onClick={this.handleShow}>
                    Sign up
                </StyledSignUpButton>
                <StyledModal
                    size="md"
                    centered
                    show={show}
                    onHide={this.handleClose}
                    aria-labelledby="register-modal"
                    className={`animated ${this.state.effect}`}
                >
                    <ModalHeader>
                        <CloseButton onClick={this.handleClose}>×</CloseButton>
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-center mb-4">
                            <h3>CREATE YOUR ACCOUNT</h3>
                        </div>
                        <StyledForm onSubmit={this.handleSubmit}>
                            <Labeltext>Username</Labeltext>
                            <StyledInput
                                type="text"
                                name="username"
                                value={username}
                                onChange={this.handleInputChange}
                                placeholder="Username"
                            />
                            <Labeltext>Email Address</Labeltext>
                            <StyledInput
                                type="email"
                                name="email"
                                value={email}
                                onChange={this.handleInputChange}
                                placeholder="Email Address"
                            />
                            <Labeltext>Password</Labeltext>
                            <StyledInput
                                type="password"
                                name="password"
                                value={password}
                                onChange={this.handleInputChange}
                                placeholder="Password"
                            />
                            <Labeltext>Referral Code (Optional)</Labeltext>
                            <StyledInput
                                name="referral"
                                value={referral}
                                onChange={this.handleInputChange}
                                placeholder="Referral Code"
                            />

                            <div>
                                <input
                                    type="checkbox"
                                    id="ruleCheck"
                                    checked={ruleChecked}
                                    onChange={this.checkRule}
                                />
                                <label htmlFor="ruleCheck" style={{ marginLeft: '10px', fontSize: '14px' }}>
                                    I agree with the user agreement
                                </label>
                            </div>
                            <ButtonContainer>
                                <SignUpButton type="submit" disabled={disabled}>
                                    {disabled && <span className="spinner-grow spinner-grow-sm mr-1" role="status" aria-hidden="true"></span>}
                                    Sign Up
                                </SignUpButton>
                                <SignInButton onClick={this.login}>Sign In</SignInButton>
                            </ButtonContainer>
                        </StyledForm>
                    </ModalBody>
                </StyledModal>
            </>
        );
    }
}

export default Register;

