import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import styled from "styled-components";
import { Search } from "lucide-react";
import { isMobile, Event } from "../../../../Helper";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";

import { Menu, X } from 'lucide-react';

const ToggleButton = styled.button`

background: none;
border: none;
color: white;
font-size: 24px;
cursor: pointer;
z-index: 1001;

`;


const HeaderContainer = styled.header`
  background-color: #23262B !important;
  height: 80px;
  display: flex;
  width: 100% !important;
  align-items: center;
  padding: 0 20px;
    z-index:1000;
  justify-content: space-between;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #1B1E22;
  border-radius: 30px;
  padding: 12px 16px;
  width: 100%;
  max-width: 600px;
  transition: outline 0.2s ease-in-out;
  border: ${props => props.isFocused ? '2px solid #4caf50' : '2px solid transparent'};
`;

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  margin-left: 8px;
  outline: none;

  &::placeholder {
    color: #a0a0a0;
  }
`;

const LogoContainer = styled.div`
  margin-right: 10px;
`;

const FullLogo = styled.img`
  height: auto;
  width: 150px;
`;

const ShortLogo = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
`;
const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 300px;

  @media (max-width: 1200px) {
    padding-right: 150px;
  }

  @media (max-width: 992px) {
    padding-right: 100px;
  }

  @media (max-width: 768px) {
    padding-right: 50px;
  }

  @media (max-width: 576px) {
    padding-right: 20px;
  }
`;


const Header = ({ isCollapsedMobile, setIsCollapsedMobile }) => {
  const [show, setShow] = useState(true);
  const [showLogo, setShowLogo] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setShowLogo(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const collapse = () => {
    setShow(!show);
    Event.emit("toggle_sidebar", show);
  };

  const renderContent = () => (
    <HeaderContainer>

      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
      {
          !showLogo &&(
            <ToggleButton onClick={() => setIsCollapsedMobile(!isCollapsedMobile)}>
            {isCollapsedMobile ? (
              <X size={24} style={{ position: 'absolute', left: '191px' }} />
            ) : (
              <Menu size={24} />
            )}
          </ToggleButton>
          )
        }
        <LogoContainer>
          <Link to="/">
            {showLogo ? (
              <FullLogo src="/assets/images/lg.png" alt="Logo" />
            ) : (
              <ShortLogo>
                <span style={{ color: '#CD7F28' }}>F</span>
                <span style={{ color: '#00BF63' }}>P</span>
              </ShortLogo>
            )}
          </Link>
        </LogoContainer>
      </div>


      <SearchBarContainer>
        <Search size={18} color="#a0a0a0" />
        <SearchInput placeholder="Game name | Provider | Category Tag" />
      </SearchBarContainer>

      <RightWrapper>
        <Login />
        <Register />
      </RightWrapper>
    </HeaderContainer>
  );

  return renderContent();
};

export default Header;