import React from 'react';
import { Card, Row, Col } from "react-bootstrap";
import Canvas from "./Canvas";
import Queue from "./Queue";
import History from "./History";
import Bet from "./Bet";
import { Helmet } from "react-helmet";
import BankRoll from "../../Components/Game/BankRoll";
import { wait, Event } from "../../../Helper";
import Help from "./Help";
import Engine from "./Engine";
import Loading from "../Loading";
import BetsPopKeno from './Graph';

class Game extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            mobile: false,
            padding: "p-1",
            showPopup: false,
        };
        this.handleResize = this.handleResize.bind(this);
        this.handleShowPopUp = this.handleShowPopUp.bind(this); 
    }

    componentDidMount() {
        let { engine } = this.state;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this._Mounted = true;
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);

        if (this.isMobile()) {
            this.setState({ height: "unset", mobile: true });
        }
        else {
            this.setState({ height: this.getHeight(), mobile: false });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        let { engine } = this.state;

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize() {
        if (this.getWidth() < 1540) {
            this.setState({ col: 'col-xl-12' });
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9' });
            Event.emit('show_min_games');
        }

        if (this.isMobile()) {
            this.setState({ mobile: true, ovh: 'ovh', padding: 'p-0' });
        }
        else {
            this.setState({ mobile: false });
        }
    }

    handleShowPopUp() {
        this.setState((prevState) => ({
            showPopup: !prevState.showPopup
        }));
    }

    getHeight() {
        return document.documentElement.clientHeight || document.body.clientHeight;
    }

    getWidth() {
        return document.documentElement.clientWidth || document.body.clientWidth;
    }

    isMobile() {
        return this.getWidth() < 992
    }

    render() {
        let { engine, col, ovh, padding } = this.state;
        return (
            <>
                <Helmet>
                    <title>Keno - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Keno Game, Online Keno Game, Bitcoin Keno Game, Blockchain Keno Game, Best Crypto Keno Game, PlayCrash.com Keno" />
                    <meta name="description" content="Play Online Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:title" content="Play Online Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Keno - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/keno" />
                </Helmet>
                <div>
                    {this._Mounted ?


                        <div className={`${col} ${padding}`} style={{ position: 'relative', width: '100%', height: '100%' }}>
                            {this.state.showPopup && (
                                <BetsPopKeno
                                    onClose={this.handleShowPopUp}
                                    engine={engine}
                                />
                            )}
                            <Row className={"animated fadeIn " + ovh}>
                                <Col sm={12} className={'m-auto ' + col + ' ' + padding}>
                                    <Card className="mb-0">
                                        <Card.Body className="px-2 py-3">
                                            <Row>
                                                <Col md={6} className={'col-6 text-left'}>
                                                    <BankRoll game={'keno'} />
                                                </Col>
                                                <Col md={6} className={'col-6 text-right'}>
                                                    <div style={{display:'flex'}}>
                                                    <div onClick={this.handleShowPopUp} style={{ marginLeft: '0px', alignItems: 'center', height: '37px', width: '37px', borderRadius: '5px', backgroundColor: "#1B2336", display: 'flex', justifyContent: 'center' }}>
    <img style={{ height: '17px', width: '17px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />

</div>                 <History engine={engine} />
                                                    <Help />
                                                   
                                                    </div>
                                                  
                                                </Col>
                                                <div onClick={this.handleShowPopUp} style={{ marginLeft: '0px', alignItems: 'center', height: '37px', width: '37px', borderRadius: '5px', backgroundColor: "#1B2336", display: 'flex', justifyContent: 'center' }}>
    <img style={{ height: '17px', width: '17px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />

</div>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <Canvas engine={engine} />

                                    <Card className="mb-0">
                                        <Card.Body className="p-1">
                                            <Row>
                                                <Col sm={12} md={12} className={'text-left m-auto'}>
                                                    <Bet engine={engine} mobile={this.state.mobile} />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>

                                    <Card className="mb-0">
                                        <Card.Body className="py-0 px-2">
                                            <Queue game={'keno'} engine={engine} t={this.props.t} />
                                        </Card.Body>
                                    </Card>
                                </Col>


                            </Row>
                        </div>
                        :
                        <Loading />
                    }
                </div>
            </>
        );
    }
}

export default Game;
