import React from 'react';
import { Card, Row, Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Engine from "./Engine";
import { isMobile, wait, Event } from "../../../Helper";
import Loading from "../Loading";
import "../../../Static/css/hash_dice.css"

class Index extends React.Component {
    _Mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            margin: 'mt-3',
            padding: "p-1"
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this._Mounted = true;
        let { engine } = this.state;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);

        wait(300).then(() => {
            this._Mounted = false;
        });

        // Stop Engine
        engine.started = false;
        engine.trigger = null;
        engine.off()
    }

    handleResize() {
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if (clientWidth < 1540) {
            this.setState({ col: 'col-xl-12' });
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9' });
            Event.emit('show_min_games');
        }

        if (isMobile()) {
            this.setState({ padding: 'p-0', margin: 'mt-1', ovh: 'ovh' });
        }
    }
    render() {
        let { col, padding, margin, ovh } = this.state;

        const help = <><p className={'font-light text-white font-15'}>
            Hash Dice is a game of betting below or under the number 50000 by the payout. If you choose “low” that means the number must come in under 49999 and higher than your payout. If you choose “High” that means  number must come in 50000 or over than your payout. First you will choose your bet amount and the payout multiplier and then your variance (low/high). The payout is the x amount times your bet amount that you will win.
        </p>
            <p className={'font-light text-white font-15'}>Example: If you bet .10 on low variance with a payout of 5 and the number comes out as 35987 you win 5x your bet amount. If the number rolled is 50000 or over you lose the bet.</p>
            <h4 className="text-white text-success">
                Provaily Fair ?
            </h4>
            <p className={'font-light text-white font-15 l26'}>
                To generate a roll number between 0 and 99,999: <br />
                First we calculate the hash value of the combination with HMAC_SHA512. This gives us a 64-character hexadecimal string: hash = HMAC_SHA512 (clientSeed:nonce, serverSeed).<br />
                We then take the first 5 characters of that hash and convert them to a decimal number ranging from 0 to 1,048,575 (16 ^ 5 - 1). <br />
                If it is less than 1 million, we divide it by 100,000 and use it as your roll outcome. Otherwise, we repeat using the next five characters. We are able to repeat the process up to 25 times.<br />
                In the very rare case ((48,576 / 1,000,000) ^ 25) that none of the 25 trials are lower than 1 million when converted to decimal, we use the remaining 3 characters and convert them to your roll number.<br />
            </p></>

        return (
            <>
                <Helmet>
                    <title>Hash Dice - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Hash Dice Game, Online Hash Dice Game, Bitcoin Hash Dice Game, Blockchain Hash Dice Game, Best Crypto Hash Dice Game, PlayCrash.com Hash Dice" />
                    <meta name="description" content={"Hash Dice is a game of betting below or under the number 50000 by the payout. If you choose “low” that means the number must come in under 49999 and higher than your payout. If you choose “High” that means  number must come in 50000 or over than your payout."} />
                    <meta name="og:title" content="Play Online Hash Dice - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Hash Dice - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/hash-dice" />
                </Helmet>
                <div >
                    {this._Mounted ?

                    
                        <Row className={"animated fadeIn " + ovh}>
                            <div className="d-none">Crypto Hash Dice Game Description: {help}</div>
                            <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                                {/* BANKROLL */}
                                <Card className="mb-0">
                                    <Card.Body className="px-2 py-3">
                                        <Row>
                                            <Col md={6} className={'col-6 text-left'}>
                                                <BankRoll game={'hash_dice'} />
                                            </Col>
                                            <Col md={6} className={'col-6 text-right'}>
                                                <TopBarGames help={help} image="/assets/images/two.dice.png" />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* GAME */}
                                <Card className="mb-0">
                                    <Card.Body className={'bg-dice-options hash_diceBG p-1'} id="roll-panel">
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto'}>
                                                <Game engine={this.state.engine} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* BET */}
                                <Card className="mb-0">
                                    <Card.Body className={padding}>
                                        <Row>
                                            <Col sm={12} md={10} className={'m-auto'}>
                                                <Bet engine={this.state.engine} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* QUEUE */}
                                <Card className="mb-5">
                                    <Card.Body className="p-1">
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto px-1'}>
                                                <Queue t={this.props.t} game={'hash_dice'} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                        :
                        <Loading />
                    }
                </div>
            </>
        );
    }
}

export default Index;
