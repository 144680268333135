// import React from "react";
// import ReactTooltip from "react-tooltip";
// import { Col, Row, Dropdown } from "react-bootstrap";
// import coins from "../../../coins";
// import socket from "../../../../Socket";
// import axios from "axios";
// import storage from "../../../../Storage";
// import Cookies from "js-cookie";
// import {
//     __,
//     decode,
//     encode,
//     getElement,
//     wait,
//     Event,
//     isMobile,
//     sendNotfication,
//     forceSatoshiFormat,
//     fixDate,
// } from "../../../../Helper";
// import DepositArchive from "./DepositArchive";
// import InrHistory from "./inrhistory";
// import C from "../../../../Constant";
// import styled from "styled-components";
// import { Copy } from "lucide-react";

// const AddressContainer = styled.div`
//   background-color: #2c2c2c;
//   border-radius: 8px;
//   padding: 16px;
//   width: 100%;
//   max-width: 400px;
// `;

// const Title = styled.h3`
//   color: #ffffff;
//   font-size: 14px;
//   margin-bottom: 8px;
// `;

// const TronHighlight = styled.span`
//   color: #00ff00;
// `;

// const AddressBox = styled.div`
//   background-color: #1c1c1c;
//   border-radius: 4px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 8px 12px;
// `;

// const Address = styled.span`
//   color: #ffffff;
//   font-family: monospace;
//   font-size: 14px;
// `;

// const CopyButton = styled.button`
//   background: none;
//   border: none;
//   cursor: pointer;
//   color: #ffffff;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 4px;

//   &:hover {
//     opacity: 0.8;
//   }
// `;

// class DepositHello extends React.Component {
//     _isMounted = false;

//     constructor(props) {
//         super(props);
//         this.state = {
//             currentCoin: props.coin, // Initialize with the prop
//             credits: {},
//             list: [],
//             content: [],
//             wallet: "btc",
//             deposit: true,
//             slide: false,
//             height: 573,
//             margin: "mt-1",
//             depositAddress: "Please Wait...",
//             amount: "",
//             utr: "",
//             image: "",
//             selectedfile: null
//         };

//     }


//     userUID() {
//         return Cookies.get('uid');
//     }

//     //  generateUuid = () => {
//     //   const hexDigits = '0123456789abcdef';
//     //   let uuidString = '';

//     //   for (let i = 0; i < 32; i++) {
//     //     if (i === 8 || i === 12 || i === 16 || i === 20) {
//     //       uuidString += '-';
//     //     }
//     //     uuidString += hexDigits[Math.floor(Math.random() * 16)];
//     //   }

//     //   return uuidString;
//     // };


//     handleFileChange = (event) => {
//         const file = event.target.files[0];
//         this.setState({ selectedfile: file });
//     };

//     handleConvertToBase64 = () => {
//         if (this.state.selectedfile) {
//             const reader = new FileReader();

//             reader.onload = (e) => {
//                 const result = e.target.result;
//                 this.setState({ image: result });
//             };

//             reader.readAsDataURL(selectedFile);
//             console.log(this.state.image);
//         } else {
//             this.setState({ image: "" });
//         }
//     };


//     // Function to handle input change
//     handleInputChange = (e) => {

//         this.setState({ amount: e.target.value });
//     };
//     // Function to handle input change
//     handleInputChangeutr = (e) => {

//         this.setState({ utr: e.target.value });
//     };



//     componentDidMount() {
//         this._isMounted = true;
//         socket.emit(C.CREDIT, encode({ coin: "inr" }));
//         socket.on(C.GET_ADDRESS, (data) => this.getAddress(decode(data)));
//         socket.on(C.CREDIT, (data) => this.getCreditAndCoins(decode(data)));

//         Event.on("deposit_archive", () => {
//             this.showArchive();
//         });

//         wait(400).then(() => {
//             this.loadAddress(true, "INR");
//         });
//     }

//     componentWillUnmount() {
//         this._isMounted = false;
//     }

//     getCreditAndCoins = (data) => {
//         if (this._isMounted) {
//             let { credit } = data;
//             this.setState({ credits: credit });
//             this.setUpCoins();
//         }
//     };

//     getFee = (coin) => {
//         let find = __.find(coins, function (o) {
//             return o.preffix === __.upperCase(coin);
//         });

//         return find.min;
//     };

//     getNetwork = (coin) => {
//         let find = __.find(coins, function (o) {
//             return o.preffix === __.upperCase(coin);
//         });

//         return find.network;
//     };

//     getCoinName = (coin) => {
//         let find = __.find(coins, function (o) {
//             return o.preffix === __.upperCase(coin);
//         });

//         return find.name;
//     };

//     setUpCoins = () => {
//         if (isMobile()) {
//             this.setState({ height: 685, margin: "mt-1" });
//         }

//         coins.forEach((item, i) => {
//             if (item.preffix === "NC") return;

//             let credit = forceSatoshiFormat(
//                 this.state.credits[__.lowerCase(item.preffix)]
//             );

//             let list = (
//                 <Dropdown.Item
//                     key={__.toString(i)}
//                     as={"button"}
//                     className={"animated fadeIn"}
//                     onClick={(e) => this.loadAddress(item.active, item.preffix)}
//                 >
//                     <span>
//                         <img
//                             src={"/assets/images/" + item.image}
//                             className={"img-fluid mini-coin mr-1"}
//                             alt="Coin"
//                         />{" "}
//                         {item.preffix}
//                     </span>
//                 </Dropdown.Item>
//             );

//             this.setState((state) => ({ list: [list, ...state.list] }));
//         });
//     };

//     showArchive = () => {
//         if (this.state.slide) Event.emit("deposit_archive_back");

//         this.setState({ slide: !this.state.slide });

//         wait(300).then(() => {
//             this.setState({ deposit: !this.state.deposit });
//         });

//         wait(300).then(() => {
//             this.loadAddress(true, "BTC");
//         });
//     };

//     copyAddress(id) {
//         var address = getElement("#" + id);
//         address.select();
//         address.setSelectionRange(0, 99999); /*For mobile devices*/
//         document.execCommand("copy");
//         sendNotfication("Copied !", "success", "top-right");
//     }

//     loadAddress(active, coin) {
//         if (this._isMounted) {
//             this.setState({ currentCoin: coin, depositAddress: "Please Wait ..." });
//             if (active) socket.emit(C.GET_ADDRESS, encode({ coin: coin }));
//             else {
//                 this.setState({ depositAddress: `${coin} not Supported yet` });
//             }
//         }
//     }

//     getAddress(data) {
//         if (!data) return;
//         let { address } = data;
//         this.setState({ depositAddress: address });
//     }

//     otherCoins() {
//         // show other coins modal
//         Event.emit("other_coins");
//     }

//     render() {
//         let { height, margin } = this.state;
//         let style,
//             size = "w-25";
//         let font = "font-10";

//         if (isMobile()) {
//             style = "col-2";
//             size = "w-50";
//         }

//         const tipNotice =
//             "Any deposit less than the minimum amount will not be accept";

//         return (
//             <div className="revi">
//                 {this.state.deposit ? (
//                     <div className={this.state.slide ? "animated fadeOut " : ""}>
//                         <Row>
//                             {/* <Col className="col-8">
//                                 <div className="media">
//                                     <img
//                                         src={"/assets/images/" + this.state.currentCoin + ".png"}
//                                         className="mr-3 thumb-sm align-self-center rounded-circle"
//                                         alt="Coin"
//                                     />
//                                     <div clasclassNames="media-body align-self-center">
//                                         <div className="coin-bal">
//                                             <h4 className="m-0 text-white">
//                                                 {forceSatoshiFormat(
//                                                     this.state.credits[
//                                                     __.lowerCase(this.state.currentCoin)
//                                                     ]
//                                                 )}
//                                             </h4>
//                                             <p className="text-muted mb-0">
//                                                 {this.getCoinName(this.state.currentCoin)}
//                                                 <span className="text-muted font-12">
//                                                     {" "}
//                                                     ({this.state.currentCoin})
//                                                 </span>
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </Col> */}
//                             {/* <Col className="col-4 text-right">
//                                 <Dropdown bottom="true">
//                                     <Dropdown.Toggle
//                                         split
//                                         align="end"
//                                         variant="mt-2 text-white"
//                                         id="dropdown-split-coins"
//                                     >
//                                         <span stle={{ fontSize: 17 }}>
//                                             <img
//                                                 src={
//                                                     "/assets/images/" + this.state.currentCoin + ".png"
//                                                 }
//                                                 className={"img-fluid mini-coin-12 mr-2"}
//                                                 alt="Coin"
//                                             />
//                                             {this.state.currentCoin}
//                                         </span>
//                                         <span className="ml-1 nav-user-name">
//                                             <span className="caret"></span>
//                                         </span>
//                                     </Dropdown.Toggle>
//                                     <Dropdown.Menu
//                                         className={"droper clist-coins user-dropdown-detail"}
//                                     >
//                                         {this.state.list}
//                                     </Dropdown.Menu>
//                                 </Dropdown>
//                             </Col> */}
//                         </Row>
//                         <hr className="border-mod" />
//                         <Row className="mb-1">
//                             <Col md="9" lg="9" sm="12">
//                                 <h5 className="text-white text-left 15 my-2">
//                                     <ReactTooltip />
//                                     Deposit Address{" "}
//                                     <span className="text-yellow font-13">
//                                         ( Minimum: {this.getFee(this.state.currentCoin)} ){" "}
//                                         <i
//                                             data-tip={tipNotice}
//                                             className="mdi mdi-help-circle font-15 float-right"
//                                         />
//                                     </span>
//                                 </h5>
//                                 <div className="input-group">
//                                     <div className="input-group-append">
//                                         <button
//                                             onClick={(e) => this.copyAddress(this.state.currentCoin)}
//                                             type="button"
//                                             className="btn bg-cs no-shadow btn-clipboard hv"
//                                         >
//                                             <i className="far fa-copy text-white" />
//                                         </button>
//                                     </div>
//                                     <input
//                                         type="text"
//                                         id={this.state.currentCoin}
//                                         className={"form-control mspace"}
//                                         style={{ background: "rgb(31 40 62)", fontSize: 11 }}
//                                         readOnly={true}
//                                         value={this.state.depositAddress}
//                                     />

//                                     <div className="input-group-append">
//                                         <button
//                                             className={
//                                                 "btn bg-cs6 no-shadow btn-clipboard hv npt " + font
//                                             }
//                                         >
//                                             Network: {this.getNetwork(this.state.currentCoin)}
//                                         </button>
//                                     </div>
//                                 </div>
//                                 {this.state.currentCoin != "INR" &&
//                                     <div style={{ marginTop: '10px' }} className="input-group">
//                                         {/* <div className="input-group-append">
//                     <button
//                       onClick={(e) => this.copyAddress(this.state.currentCoin)}
//                       type="button"
//                       className="btn bg-cs no-shadow btn-clipboard hv"
//                     >
//                      <div  style={{color:'yellow'}} className="c2">Add File</div>
//                     </button>
//                   </div> */}
//                                         <input type="text" className={"form-control mspace"} id="amount" name="amount" style={{ background: "rgb(31 40 62)", fontSize: 11 }}
//                                             placeholder="Enter Amount" value={this.state.amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />

//                                         <div className="input-group-append">

//                                             <button
//                                                 onClick={() => {
//                                                     const parsedAmount = parseInt(this.state.amount.trim(), 10);
//                                                     console.log(parsedAmount);
//                                                     if (this.state.amount) {
//                                                         sendNotfication('Submitted successfully', 'success', 'top-right');
//                                                     } else {
//                                                         sendNotfication("Please enter a valid amount", "error", "top-right");
//                                                     }
//                                                     // if (this.state.amount !== "0.0000000" && this.state.amount !== " " &&  !isNaN(parsedAmount) && parsedAmount !== 0 && !(parsedAmount < 1000)) {

//                                                     //   sendNotfication('Submitted successfully', 'success', 'top-right');
//                                                     // } else {
//                                                     //   sendNotfication("Please enter a valid amount", "error", "top-right");
//                                                     // }

//                                                 }}
//                                                 type="button"
//                                                 className="btn bg-cs no-shadow btn-clipboard hv"
//                                             >
//                                                 <div style={{ color: 'yellow' }} className="c2">Submit</div>
//                                             </button>
//                                         </div>
//                                     </div>}
//                                 {this.state.currentCoin == "INR" && (<div style={{ marginTop: '10px' }} className="input-group">
//                                     <input type="text" className={"form-control mspace"} id="amount" name="amount" style={{ background: "rgb(31 40 62)", fontSize: 11 }}
//                                         placeholder="Enter Amount" value={this.state.amount} autoComplete={"off"} onKeyUp={this.handleInputChange} onChange={this.handleInputChange} />

//                                     <div className="input-group-append">

//                                         <button
//                                             onClick={() => {
//                                                 const parsedAmount = parseInt(this.state.amount.trim(), 10);

//                                                 console.log(parsedAmount);

//                                                 if (this.state.amount !== "0" && this.state.amount !== " " && !isNaN(parsedAmount) && parsedAmount !== 0 && !(parsedAmount < 1000)) {
//                                                     // window.open("https://razorpay.me/@bskenterprise", '_blank');
//                                                 } else {
//                                                     sendNotfication("Please enter a valid amount", "error", "top-right");
//                                                 }
//                                             }}
//                                             type="button"
//                                             className="btn bg-cs no-shadow btn-clipboard hv"
//                                         >
//                                             <div style={{ color: 'yellow' }} className="c2">Deposit</div>

//                                         </button>
//                                     </div>



//                                 </div>)}
//                                 {this.state.currentCoin == "INR" && (<div style={{ marginTop: '10px' }} className="input-group">


//                                     <input type="text" className={"form-control mspace"} id="utr" name="utr" style={{ background: "rgb(31 40 62)", fontSize: 11 }}
//                                         placeholder="Enter Transaction ID/UTR" value={this.state.utr} autoComplete={"off"} onKeyUp={this.handleInputChangeutr} onChange={this.handleInputChangeutr} />
//                                     <div className="input-group-append">

//                                         <button
//                                             onClick={async () => {
//                                                 if (this.state.utr !== "") {
//                                                     var uuid = this.userUID();
//                                                     console.log(uuid);
//                                                     var username = storage.getKey('name');
//                                                     console.log(username);
//                                                     const currentDate = new Date().toISOString();
//                                                     console.log(currentDate);


//                                                     axios({
//                                                         method: 'POST',
//                                                         url: 'https://api.winstreak.games/hr',
//                                                         headers: {
//                                                             'Access-Control-Allow-Origin': '*',
//                                                             'Access-Control-Allow-Methods': '*',
//                                                             'Content-Type': 'application/json',
//                                                             'Accept': 'application/json',
//                                                         },
//                                                         data: {
//                                                             uid: uuid,
//                                                             date: currentDate,
//                                                             amount_deposit: this.state.amount,
//                                                             txid_in: this.state.utr,
//                                                             status: 'ongoing',
//                                                             name: username
//                                                         },
//                                                     })
//                                                         .then(response => {

//                                                             console.log(response);
//                                                             return response; // Assuming the actual data is in response.data
//                                                         })
//                                                         .then(data => {
//                                                             this.setState({ amount: '' });
//                                                             this.setState({ utr: '' });
//                                                             sendNotfication('Submitted successfully', 'success', 'top-right');
//                                                         })
//                                                         .catch(error => {
//                                                             console.error(error);
//                                                             sendNotfication("error occured", 'error', 'top-right');
//                                                         });

//                                                 } else {
//                                                     sendNotfication("Please enter transaction UTR/ID", "error", "top-right");
//                                                 }
//                                             }}
//                                             type="button"
//                                             className="btn bg-cs no-shadow btn-clipboard hv"
//                                         >
//                                             <div style={{ color: 'yellow' }} className="c2">Submit</div>



//                                         </button>
//                                     </div>
//                                 </div>)}
//                                 {this.state.currentCoin == "INR" && (<div
//                                     style={{ marginTop: '15px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '2px' }}
//                                     className="input-group-append">

//                                     <button
//                                         onClick={() => {
//                                             if (this.state.amount !== "0" && this.state.amount !== "") {
//                                                 window.open("https://razorpay.me/@bskenterprise", '_blank');
//                                             } else {
//                                                 sendNotfication("Please enter a valid amount", "error", "top-right");
//                                             }
//                                         }}
//                                         type="button"
//                                         className="btn bg-cs no-shadow btn-clipboard hv"
//                                     >
//                                         <div style={{ color: 'yellow' }} className="c2">Deposit With Payment Gateway</div>

//                                     </button>
//                                 </div>)}
//                                 {this.state.currentCoin == "BRX" && (
//                                     <div className="alert bg-soft-info nhv mb-0">
//                                         <p className="text-red">
//                                             Please confirm payment after deposit:{" "}
//                                             <a
//                                                 href="https://forms.gle/GNhWMi7kvNGaVN8i6"
//                                                 target="_blank"
//                                                 onClick={() =>
//                                                     window.open("https://forms.gle/GNhWMi7kvNGaVN8i6")
//                                                 }
//                                             >
//                                                 click here
//                                             </a>
//                                         </p>
//                                     </div>
//                                 )}
//                                 {/* <Col className="ycenter" md="3" lg="3" sm="12"  > */}
//                                 {this.state.currentCoin === 'INR' ?
//                                     <div className="text-center">
//                                         <img
//                                             src={"/assets/images/inrqr.jpeg"
//                                             }
//                                             className={"img-thumbnail qri mt-2 " + size}
//                                             alt="Deposit Address"
//                                         />
//                                     </div>
//                                     :
//                                     <div className="text-center">
//                                         {/* <img
//                     // src={
//                     //   "https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=" +
//                     //   this.state.depositAddress
//                     // }
//                     className={"img-thumbnail qri mt-2 " + size}
//                     alt="Deposit Address"
//                   /> */}
//                                     </div>
//                                 }

//                                 <div className="alert bg-soft-info nhv mb-0">
//                                     <p className="text-white font-10 mb-0">
//                                         <span className="text-yellow font-10">NOTE : </span>
//                                         Contact support via Live chat if the processing of your deposit is taking too long or if you are experiencing any other deposit-related problems.
//                                     </p>
//                                 </div>

//                                 {/* </Col> */}
//                                 {/* <div className="bns">
//                   <div className="c1 ctss">First Deposit Bonus</div>
//                   <div className="c2">200%</div>
//                 </div>
//                 <div className="bns">
//                   <div className="c1 ctss">Second Deposit Bonus</div>
//                   <div className="c2">100%</div>
//                 </div>
//                 <div className="bns">
//                   <div className="c1 ctss">Third Deposit Bonus</div>
//                   <div className="c2">50%</div>
//                 </div> */}
//                             </Col>

//                             <AddressContainer>
//         <Title>Deposit Address ( Note: Only <TronHighlight>TRON</TronHighlight> )</Title>
//         <AddressBox>
//           <Address>{ this.state.depositAddress}</Address>
//           <CopyButton onClick={this.copyToClipboard}>
//             <Copy size={18} />
//           </CopyButton>
//         </AddressBox>
//       </AddressContainer>

//                         </Row>
//                         <div className="alert bg-soft-info nhv mb-0">
//                             <p className="text-white font-13 mb-0">
//                                 <span className="text-yellow">Important : </span>
//                                 Send only {this.state.currentCoin} to this deposit address.
//                                 Sending any other currency to this address may result in the
//                                 loss of your deposit. Please be careful with the minimum deposit
//                                 amount and network.
//                             </p>
//                             <p className="text-white font-13 mb-0">
//                                 <span className="text-yellow">Notice : </span>
//                                 We don't need to check payments network confirmation except for Fiat Deposits. After
//                                 making a crypto deposit, your account should be charge quickly, Fiat deposits can take UPTO 1hrs. But at
//                                 the time of withdrawal, your deposit must have at least 1
//                                 confirmation.
//                                 If you are facing any issue in depositing your amount contact support via live chat.
//                             </p>

//                             {/* <p className="text-white font-13 mb-0">
//                 <span className="text-yellow">Note : </span>
//                 Only for INDIAN Users, As its a crypto casino website made on Blockchain and web 3.0 platform and INR is not a crypto currency, INR is only
//                 for Deposits and Withdrawal purposes not for playing Kindly swap your funds to crypto before playing. Withdrawal will only be processed if played in crypto currency and after meeting wagering requirements.
//               </p> */}
//                         </div>
//                     </div>
//                 ) : this.state.currentCoin == 'INR' ?
//                     (
//                         <>
//                             <div
//                                 className={this.state.slide == false ? "animated fadeOut" : ""}
//                             >
//                                 <InrHistory />
//                             </div>
//                         </>
//                     )
//                     :
//                     (
//                         <>
//                             <div
//                                 className={this.state.slide == false ? "animated fadeOut" : ""}
//                             >
//                                 <DepositArchive />
//                             </div>
//                         </>
//                     )}
//             </div>
//         );
//     }
// }

// export default DepositHello;





import React from "react";
import ReactTooltip from "react-tooltip";
import { Col, Row, Dropdown } from "react-bootstrap";
import coins from "../../../coins";
import socket from "../../../../Socket";
import axios from "axios";
import storage from "../../../../Storage";
import Cookies from "js-cookie";
import { QRCodeSVG } from 'qrcode.react';
import {
    __,
    decode,
    encode,
    getElement,
    wait,
    Event,
    isMobile,
    sendNotfication,
    forceSatoshiFormat,
    fixDate,
} from "../../../../Helper";
import DepositArchive from "./DepositArchive";
import InrHistory from "./inrhistory";
import C from "../../../../Constant";
import styled from "styled-components";
import { Check, Copy } from "lucide-react";

const AddressContainer = styled.div`
  border-radius: 8px;
  padding: 8px;
  width: 100%;
`;

const Title = styled.h3`
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 8px;
`;

const TronHighlight = styled.span`
  color: #00ff00;
`;

const AddressBox = styled.div`
  background-color: #25282C;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
`;

const Address = styled.span`
  color: #ffffff;
  font-family: monospace;
  font-size: 14px;
`;

const CopyButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;

  &:hover {
    opacity: 0.8;
  }
`;


const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1E2024;
  border-radius: 14px;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 32px; /* Default for larger screens */

  @media (max-width: 1200px) { /* For tablets or medium screens */
    gap: 24px;
  }

  @media (max-width: 768px) { /* For mobile screens */
    gap: 16px;
  }

  @media (max-width: 480px) { /* For smaller mobile screens */
    gap: 0px;
  }
`;


const NavButton = styled.button`
  display: flex;
  align-items: center;

  background-color: ${props => props.active ? '#4caf50' : 'transparent'};
  color: ${props => props.active ? 'white' : '#8c8c8c'};
  border: none;
  padding: 12px 20px;
  border-radius: 14px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-size: 14px;
  gap:8px;

  &:hover {
    background-color: ${props => props.active ? '#4caf50' : '#2c2c2c'};
  }

  svg {
    margin-right: 8px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  background-color: #1e2124;
  border-radius: 4px;
  padding: 4px;
  gap:10px;
`;

const Button = styled.div`
  background-color: ${props => props.active ? '#28331F' : '#6282D'};
  color: ${props => props.active ? '#ffffff' : '#a0a0a0'};
  border: ${props => props.active ? '1px solid #43B309' : '1px solid gray'};
 
  border-radius:18px;
  padding: 8px 18px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: ${props => props.active ? 'bold' : 'normal'};

//   &:hover {
//     background-color: ${props => props.active ? '#3b4045' : '#2a2e31'};
//   }

//   &:first-child {
//     border-top-left-radius: 4px;
//     border-bottom-left-radius: 4px;
//   }

//   &:last-child {
//     border-top-right-radius: 4px;
//     border-bottom-right-radius: 4px;
//   }
`;




class DepositHello extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            currentCoin: props.coin, // Initialize with the prop
            credits: {},
            list: [],
            content: [],
            wallet: "btc",
            deposit: true,
            slide: false,
            height: 573,
            margin: "mt-1",
            depositAddress: "Please Wait...",
            amount: "",
            utr: "",
            image: "",
            selectedfile: null,
            activeNetwork: 'ERC20',
            showCheck: false
        };

    }


    userUID() {
        return Cookies.get('uid');
    }
    setActiveNetwork = (network) => {
        this.setState({ activeNetwork: network });
        const chain=this.getChainValue(network)
        socket.emit(C.GET_ADDRESS, encode({ coin: this.state.currentCoin,chain: chain }))
    }

    //  generateUuid = () => {
    //   const hexDigits = '0123456789abcdef';
    //   let uuidString = '';

    //   for (let i = 0; i < 32; i++) {
    //     if (i === 8 || i === 12 || i === 16 || i === 20) {
    //       uuidString += '-';
    //     }
    //     uuidString += hexDigits[Math.floor(Math.random() * 16)];
    //   }

    //   return uuidString;
    // };


    handleFileChange = (event) => {
        const file = event.target.files[0];
        this.setState({ selectedfile: file });
    };

    handleConvertToBase64 = () => {
        if (this.state.selectedfile) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const result = e.target.result;
                this.setState({ image: result });
            };

            reader.readAsDataURL(selectedFile);
            console.log(this.state.image);
        } else {
            this.setState({ image: "" });
        }
    };


    // Function to handle input change
    handleInputChange = (e) => {

        this.setState({ amount: e.target.value });
    };
    // Function to handle input change
    handleInputChangeutr = (e) => {

        this.setState({ utr: e.target.value });
    };



    componentDidMount() {
        this._isMounted = true;
        socket.emit(C.CREDIT, encode({ coin: this.props.coin }));
        socket.on(C.GET_ADDRESS, (data) => this.getAddress(decode(data)));
        socket.on(C.CREDIT, (data) => this.getCreditAndCoins(decode(data)));

        Event.on("deposit_archive", () => {
            this.showArchive();
        });

        wait(400).then(() => {
            this.loadAddress(true, this.props.coin);
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getCreditAndCoins = (data) => {
        if (this._isMounted) {
            let { credit } = data;
            this.setState({ credits: credit });
            this.setUpCoins();
        }
    };

    getFee = (coin) => {
        let find = __.find(coins, function (o) {
            return o.preffix === __.upperCase(coin);
        });

        return find.min;
    };

    getNetwork = (coin) => {
        let find = __.find(coins, function (o) {
            return o.preffix === __.upperCase(coin);
        });

        return find.network;
    };

    getCoinName = (coin) => {
        let find = __.find(coins, function (o) {
            return o.preffix === __.upperCase(coin);
        });

        return find.name;
    };

    setUpCoins = () => {
        if (isMobile()) {
            this.setState({ height: 685, margin: "mt-1" });
        }

        coins.forEach((item, i) => {
            if (item.preffix === "NC") return;

            let credit = forceSatoshiFormat(
                this.state.credits[__.lowerCase(item.preffix)]
            );

            let list = (
                <Dropdown.Item
                    key={__.toString(i)}
                    as={"button"}
                    className={"animated fadeIn"}
                    onClick={(e) => this.loadAddress(item.active, item.preffix)}
                >
                    <span>
                        <img
                            src={"/assets/images/" + item.image}
                            className={"img-fluid mini-coin mr-1"}
                            alt="Coin"
                        />{" "}
                        {item.preffix}
                    </span>
                </Dropdown.Item>
            );

            this.setState((state) => ({ list: [list, ...state.list] }));
        });
    };

    showArchive = () => {
        if (this.state.slide) Event.emit("deposit_archive_back");

        this.setState({ slide: !this.state.slide });

        wait(300).then(() => {
            this.setState({ deposit: !this.state.deposit });
        });

        wait(300).then(() => {
            this.loadAddress(true, "BTC");
        });
    };

    copyAddress(id) {
        var address = getElement("#" + id);
        address.select();
        address.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");
        sendNotfication("Copied !", "success", "top-right");
    }

    getChainValue = (network) => {
        switch (network) {
          case 'ERC20':
            return 'erc';
          case 'BEP20':
            return 'bep';
          case 'TRC20':
            return 'trc';
          default:
            return '';
        }
      }

    loadAddress(active, coin) {
        console.log("coinn", coin);
        // console.log('chain', chain)
        if (this._isMounted) {
            this.setState({ currentCoin: coin, depositAddress: "Please Wait ..." });
            const chain = this.getChainValue(this.state.activeNetwork);
            if (active) socket.emit(C.GET_ADDRESS, encode({ coin: coin,chain: chain }));//er20 - erc , trc20 - trc , bep20 - bep
            else {
                this.setState({ depositAddress: `${coin} not Supported yet` });
            }
        }
    }
    copyToClipboard = () => {
        const textArea = document.createElement("textarea");
        textArea.value = this.state.depositAddress;
        document.body.appendChild(textArea);
        textArea.select();
        
        try {
            document.execCommand('copy');
            // Show check icon
            this.setState({ showCheck: true });
            // Reset back to copy icon after 2 seconds
            setTimeout(() => {
                this.setState({ showCheck: false });
            }, 2000);
        } catch (err) {
            console.error('Failed to copy address', err);
        } finally {
            document.body.removeChild(textArea);
        }
    }

    getAddress(data) {
        if (!data) return;
        let { address } = data;
        console.log("adresss", address);
        this.setState({ depositAddress: address });
    }

    otherCoins() {
        // show other coins modal
        Event.emit("other_coins");
    }

    render() {
        let { height, margin } = this.state;
        const networks = ['ERC20', 'BEP20', 'TRC20'];

        let style,
            size = "w-25";
        let font = "font-10";

        if (isMobile()) {
            style = "col-2";
            size = "w-50";
        }

        const tipNotice =
            "Any deposit less than the minimum amount will not be accept";

        return (
            <div className="revi">
                {this.state.deposit ? (
                    <div className={this.state.slide ? "animated fadeOut " : ""}>
                        
                        <AddressContainer>
                {/* <div>{this.state.currentCoin}</div> */}
                <div style={{color:'gray'}}>Choose Network</div>
                {
                    this.state.currentCoin === 'USDT' && (
                        <ButtonGroup>
                            {networks.map(network => (
                                <Button
                                    key={network}
                                    active={this.state.activeNetwork === network}
                                    onClick={() => {
                                        this.setActiveNetwork(network)
                                    }}
                                >
                                    {network}
                                </Button>
                            ))}
                        </ButtonGroup>
                    )
                }
                <Title>Deposit Address ( Note: Only <TronHighlight>{this.props.coin}</TronHighlight> )</Title>
                <AddressBox>
                    <Address>{this.state.depositAddress}</Address>
                    <CopyButton onClick={this.copyToClipboard}>
                        {this.state.showCheck ? <Check size={18} color="green" /> : <Copy size={18} />}
                    </CopyButton>
                </AddressBox>
            </AddressContainer>
        

                        {this.state.currentCoin == "BRX" && (
                            <div className="alert bg-soft-info nhv mb-0">
                                <p className="text-red">
                                    Please confirm payment after deposit:{" "}
                                    <a
                                        href="https://forms.gle/GNhWMi7kvNGaVN8i6"
                                        target="_blank"
                                        onClick={() =>
                                            window.open("https://forms.gle/GNhWMi7kvNGaVN8i6")
                                        }
                                    >
                                        click here
                                    </a>
                                </p>
                            </div>
                        )}
                        {/* <Col className="ycenter" md="3" lg="3" sm="12"  > */}
                        {this.state.currentCoin === 'INR' ?
                            <div className="text-center">
                                <img
                                    src={"/assets/images/inrqr.jpeg"
                                    }
                                    className={"img-thumbnail qri mt-2 " + size}
                                    alt="Deposit Address"
                                />
                            </div>
                            :
                            <div className="text-center">
                                {/* <img
                    // src={
                    //     "https://chart.googleapis.com/chart?chs=500x500&cht=qr&chl=" + 
                    //     encodeURIComponent(this.state.depositAddress)
                    //   }
                      
                    className={"img-thumbnail qri mt-2 " + size}
                    alt="Deposit Address"
                  /> */}
                                <QRCodeSVG value={this.state.depositAddress} size={150} />
                            </div>
                        }


                    </div>
                ) : this.state.currentCoin == 'INR' ?
                    (
                        <>
                            <div
                                className={this.state.slide == false ? "animated fadeOut" : ""}
                            >
                                <InrHistory />
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div
                                className={this.state.slide == false ? "animated fadeOut" : ""}
                            >
                                <DepositArchive />
                            </div>
                        </>
                    )}
            </div>
        );
    }
}

export default DepositHello;

