import React from 'react';
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { isMobile, wait, Event } from "../../../Helper";
import BankRoll from "../../Components/Game/BankRoll";
import Queue from "../../Components/Game/Queue";
import TopBarGames from "../../Components/Game/Addons/TopBar";
import Game from "./Game";
import Bet from "./Bet";
import Engine from "./Engine";
import Loading from "../Loading";
import "../../../Static/css/hilo.css"
import BetsPopHilo from './Graph';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            engine: new Engine(),
            padding: "p-1",
            showPopup: false,
        };
        this.handleResize = this.handleResize.bind(this);
        this.handleShowPopUp = this.handleShowPopUp.bind(this); 
    }

    
    handleShowPopUp() {
        this.setState((prevState) => ({
            showPopup: !prevState.showPopup
        }));
    }

    componentDidMount() {
        this._Mounted = true;
        let { engine } = this.state;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        // Start Engine
        engine.started = true;

        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        let { engine } = this.state;
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;

        // Stop Engine
        engine.started = false;
        engine.off()
    }

    handleResize() {
        let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if (clientWidth < 1540) {
            this.setState({ col: 'col-xl-12' });
            Event.emit('hide_games');
        }
        else {
            this.setState({ col: 'col-xl-9' });
            Event.emit('show_min_games');
        }
        if (isMobile()) {
            this.setState({ padding: 'p-0', ovh: 'ovh' });
        }
    }

    render() {
        let { col, padding, ovh } = this.state;

        const help = <><p className={'font-light text-white font-15'}>
            Hi-lo, also referred to as high low boasts incredibly simple and straightforward rules.
            The aim of the game is to guess whether the next card to be dealt will be higher or lower.
            Guess right and you win, guess wrong and you lose, it’s that simple.
            <b>Hilo Tips & Tricks</b>
            Place your bet on the table<br />
            A card is dealt <br />
            Choose whether the next card will be higher or lower<br />
            The next card is dealt<br />
            If you guessed incorrectly, your stake is lost, and the game round ends<br />
            You must place another bet to play again<br />
            If you guessed correctly, you win a payout<br />
            The result card for the round becomes the base card for the next round<br />
            Play again or collect your balance
        </p>
            <h4 className="text-white text-yellow">
                How to Play ?
            </h4>
            <p className={'font-light text-white font-15'}>
                The game is not timed.
                Click "Bet" to start betting and get the first card.
                <br />
                At this point you can guess the next card is "higher or same" or "lower or same" or “skip”.
                <br />
                If you guess right, you will get the corresponding payout.
                <br />
                You can choose to claim the win and stop this round or continue guessing the next card.
                <br />
                The more cards you guess, the bigger payout you get.
                <br />
                There is no max payout, only bet and profit limits.
                <br />
                Ace is the lowest card, king is the highest card. The value order is K,Q,J,10,9,8,7,6,5,4,3,2,A.
            </p></>

        return (
            <>
                <Helmet>
                    <title>Hilo - Crypto Casino Games</title>
                    <meta name="keywords" content="Crypto Hilo Game, Online Hilo Game, Bitcoin Hilo Game, Blockchain Hilo Game, Best Crypto Hilo Game, PlayCrash.com Hilo" />
                    <meta name="description" content="Crypto Hilo Game, also referred to as high low boasts incredibly simple and straightforward rules.The aim of the game is to guess whether the next card to be dealt will be higher or lower. Guess right and you win, guess wrong and you lose, it’s that simple. " />
                    <meta name="og:title" content="Play Online Hilo - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <meta name="og:description" content="Play Online Hilo - Play with BTC, ETH LTC, USDT, BCH, TRX and many more !" />
                    <link rel="canonical" href="/hilo" />
                </Helmet>
                <div>
                    {this._Mounted ?
                 <div className={`${col} ${padding}`} style={{ position: 'relative', width: '100%', height: '100%' }}>
                 {this.state.showPopup && (
                   <BetsPopHilo
                     onClose={this.handleShowPopUp}
                     engine={this.state.engine}
                   />
                 )}
                        <Row className={"animated fadeIn " + ovh}>
                            <div className="d-none">Crypto Hilo Game Description: {help}</div>
                            <Col sm={12} className={'m-auto ' + col + ' ' + padding}>

                                {/* BANKROLL */}
                                <Card className="mb-0">
                                    <Card.Body className="px-2 py-3">
                                        <Row>
                                            <div></div>
                                            
                                            <Col md={6} className={'col-6 text-left'}>
                                                <BankRoll game={'hilo'} />
                                            </Col>
                                            <div style={{display:'flex'}}>

                                            <Col md={6} className={'col-6 text-right'}>
                                                <TopBarGames help={help} image="/assets/images/covers_new/____hilo.png" />
                                            </Col>
                                            <div onClick={this.handleShowPopUp} style={{ marginLeft: '26px', alignItems: 'center', height: '37px', width: '37px', borderRadius: '5px', backgroundColor: "#1B2336", display: 'flex', justifyContent: 'center' }}>
                                                        <img style={{ height: '17px', width: '17px' }} src="/assets/images/nanoicons/nanoswap.png" alt="Swap" />

                                                    </div>
                                            </div>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* GAME */}
                                <Card className="mb-0">
                                    <Card.Body className={'bg-dice-options p-1'} id="roll-panel">
                                        <Row>
                                            <Col sm={12} md={10} className={'m-auto'}>
                                                <Game engine={this.state.engine} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* BET */}
                                <Card className="mb-0">
                                    <Card.Body className={padding}>
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto'}>
                                                <Bet engine={this.state.engine} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                                {/* QUEUE */}
                                <Card className="mb-5">
                                    <Card.Body className="p-1">
                                        <Row>
                                            <Col sm={12} md={12} className={'m-auto px-1'}>
                                                <Queue t={this.props.t} game={'hilo'} />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                        </div>
                        :
                        <Loading />
                    }
                </div>
            </>
        );
    }
}

export default Index;
